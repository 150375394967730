import React, { useEffect, useState, useRef } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch } from "react-redux";
import { TbPoint } from "react-icons/tb";
import { IoMdMove } from "react-icons/io";
import { FaRegCircle } from "react-icons/fa";
import ResponseTabs from "./components/ResponseTabs";
import Controls from "./components/Controls";
import { setKeyValue } from "redux/slices/QuestionSlice";
import { GraphOptions, GraphData } from "./SubChildren/GraphingUnit";
import useBoard from "./useBoard";
import useBoardEvents from "./useBoardEvents";
import { EditSubQues } from "redux/slices/EditSubQuestionSlice";
import { IoIosUndo, IoIosRedo, IoIosClose } from "react-icons/io";
import { RxReset } from "react-icons/rx";
import uuid from "react-uuid";
import { debounce } from "utils/generalUtils";
import {
  getNewLabelObjectState,
  getObjectClicked,
  handleMovingObject,
  handlePointClick,
  handlePolygonClick,
  handleTwoPointsShape,
} from "./utils/boardUtils";
import { isEqual } from "lodash";
import {
  buttonsSettings,
  drawParabola,
  drawSine,
  shapesRenderer,
} from "utils/graph/shapeDrawerUtil";

interface INewGraphProps {
  currentQuestion: any | undefined;
  optionItems?: Partial<GraphOptions> | GraphOptions | any;
  questiontype: "add" | "edit" | "preview" | "shaded";
  data?: Partial<GraphData> | GraphData | undefined;
  showAnswer?: boolean;
}

interface GraphFormData {
  correct_answer: {
    valid_response: {
      value: any[];
      score: number;
      tools: any[];
    };
    alt_responses: Array<{
      value: any[];
      score: number;
      tools: any[];
      tabId?: string;
    }>;
  };
}

interface BoardObject {
  elType: string;
  name: string;
  ticks: {
    ticksDistance: number;
  };
}

export default function GraphingComponent({
  currentQuestion,
  optionItems,
  questiontype,
  data,
  showAnswer,
}: INewGraphProps) {
  const dispatch = useDispatch();
  const customGraphData = data;
  const [graphSettings, setGraphSettings] = useState(() => {
    if (questiontype === "edit") {
      return currentQuestion;
    }
    return data;
  });
  const DEFAULT_ALT_ANSWER = { tabId: uuid(), value: [], score: 0, tools: [] };
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [selectedButton, setSelectedButton] = useState("Point");
  const [customGraphOptions, setCustomGraphOptions] = useState<any[]>(() => {
    if (questiontype === "edit") {
      if (
        currentQuestion?.correct_answer?.valid_response?.shapes?.length > 0 ||
        currentQuestion?.correct_answer?.alt_responses?.length > 0
      ) {
        const validShapes =
          currentQuestion?.correct_answer?.valid_response?.shapes;
        const altShapes =
          currentQuestion?.correct_answer?.alt_responses?.flatMap(
            (response) => response.shapes
          );
        return [...validShapes, ...altShapes];
      }
    } else {
      return [];
    }
    return [];
  });
  const [customGraphOptionsIndex, setCustomGraphOptionsIndex] = useState(null);
  const [redoData, setRedoData] = useState<any[]>([]);
  const [labelValue, setLabelValue] = useState("");
  const [labelPosition, setLabelPosition] = useState({ x: 0, y: 0 });
  const [activeShape, setActiveShape] = useState(false);
  const [labelState, setLabelState] = useState({
    element: null,
    x: 0,
    y: 0,
    selectedObject: null,
    isChild: false,
  });
  const [userFinishedDrawing, setUserFinishedDrawing] =
    useState<boolean>(false);
  const boardRef = useRef<HTMLDivElement | null>(null);
  const board = useRef<JXG.Board | null>(null);
  const [hasGraphOptions, setHasGraphOptions] = useState(false);
  const graphPoints = useRef<any[]>([]);
  const [deleteStart, setDeleteStart] = useState(false);
  const deleteStartRef = useRef(false);
  const [correctAnswer, setCorrectAnswer] = useState<any>([]);
  const [boardResetting, setBoardResetting] = useState(false);
  const [deletedElements, setDeletedElements] = useState<any[]>([]);
  const pointsRef = useRef({ points: [], shapeDrawer: null });
  const elementsRef = useRef<any[]>([]);
  const selectedButtonRef = useRef(selectedButton);
  // const [backgroundShapes, setBackgroundShapes] = useState<any[]>([]);
  const [graphData, setGraphData] = useState<any>(
    customGraphData || {
      correct_answer: {
        valid_response: {
          value: [],
          score: 0,
          tools: [],
        },
        alt_responses: [],
      },
    }
  );
  const { register, control, setValue, watch } = useForm<GraphFormData>({
    defaultValues: graphData,
  });
  const { fields, remove } = useFieldArray({
    control,
    name: "correct_answer.alt_responses",
  });
  const watchedFields = watch("correct_answer.alt_responses");

  const graphRef = useRef<(HTMLDivElement | null)[]>([]);
  const [altTabs, setAltTabs] = useState(fields);
  const previousSettings = useRef(graphSettings);
  const tabsMap = useRef<Map<number, JXG.Board>>(new Map());
  const graphShapesRef = useRef<any[]>([]);

  const significantSettingsKeys = [
    "more_options.layout.width",
    "more_options.layout.height",
    "more_options.grid.x_distance",
    "more_options.grid.y_distance",
    "options.canvas.x_min",
    "options.canvas.y_max",
    "options.canvas.x_max",
    "options.canvas.y_min",
    "more_options.axis_x.ticks_distance",
    "more_options.axis_y.ticks_distance",
    "more_options?.background_image?.src",
  ];

  // Helper function to get nested property value
  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  // Helper function to check if significant settings have changed
  const hasSignificantChanges = (prevSettings, currentSettings) => {
    return significantSettingsKeys.some((key) => {
      const prevValue = getNestedValue(prevSettings, key);
      const currentValue = getNestedValue(currentSettings, key);
      return !isEqual(prevValue, currentValue);
    });
  };

  useEffect(() => {
    if (board.current) {
      // Check if significant changes require re-initialization
      console.log(graphSettings);
      if (hasSignificantChanges(previousSettings.current, graphSettings)) {
        board.current.removeChild(board.current);
        board.current = null;
        initializeBoard(activeTabIndex);
      } else {
        updateBoardProperties();
      }
      if (
        graphSettings?.more_options?.background_image?.src !== "" &&
        questiontype !== "shaded"
      ) {
        const src = graphSettings?.more_options?.background_image?.src;
        const width = Number(
          graphSettings?.more_options?.background_image?.width || 100
        );
        const height = Number(
          graphSettings?.more_options?.background_image?.height || 100
        );
        const xMax = graphSettings?.options?.canvas?.x_max;
        const yMax = graphSettings?.options?.canvas?.y_max;
        const imgWidth = ((xMax + xMax) * width) / 100;
        const imgHeight = ((yMax + yMax) * height) / 100;
        const startxPos =
          imgWidth / 2 -
            Number(graphSettings?.more_options?.background_image?.x) || 0;
        const startyPos =
          imgHeight / 2 -
            Number(graphSettings?.more_options?.background_image?.y) || 0;

        board.current.create(
          "image",
          [src, [-startxPos, -startyPos], [imgWidth, imgHeight]],
          {
            opacity:
              Number(graphSettings?.more_options?.background_image?.opacity) /
                100 || 1,
            fixed: true,
            draggable: false,
          }
        );
      }

      if (graphSettings?.more_options?.background_shapes.length > 0) {
        shapesRenderer(
          board.current,
          graphSettings?.more_options?.background_shapes,
          false,
          graphPoints,
          "shaded",
          graphSettings?.more_options?.layout?.snapto,
          {
            strokeColor: "gray",
            borders: {
              strokeColor: "gray",
            },
            draggable: false,
            fixed: true,
            visibilityOnBoard:
              graphSettings?.more_options?.background_image?.display_points,
          }
        );
        board.current.update();
      }
     
    } else {
      initializeBoard(activeTabIndex);
    }

    previousSettings.current = graphSettings;
  }, [graphSettings, questiontype]);
  const initializeBoard = (tabIndex: number) => {
    if (boardRef.current) {
      board.current = JXG.JSXGraph.initBoard(boardRef.current, {
        boundingbox: [
          graphSettings?.options?.canvas?.x_min,
          graphSettings?.options?.canvas?.y_max,
          graphSettings?.options?.canvas?.x_max,
          graphSettings?.options?.canvas?.y_min,
        ],
        axis: false,
        keepaspectratio: false,
        showNavigation: false,
        showCopyright: false,
        pan: { enabled: true },
        showInfobox:
          graphSettings?.more_options?.layout?.show_on_hover || false,
      });

      // Add grid and axes
      board.current.create("grid", [], {
        majorStep: [
          Number(graphSettings?.more_options?.grid?.x_distance) > 0
            ? Number(graphSettings?.more_options?.grid?.x_distance)
            : 1,
          Number(graphSettings?.more_options?.grid?.y_distance) > 0
            ? Number(graphSettings?.more_options?.grid?.y_distance)
            : 1,
        ],
      });

      // Add X and Y axes
      board.current.create(
        "axis",
        [
          [0, 0],
          [1, 0],
        ],
        {
          name: graphSettings?.more_options?.axis_x?.axis_label,
          withLabel: graphSettings?.more_options?.axis_x?.show_axis_label,
          label: {
            offset: [
              -(graphSettings?.more_options?.axis_x?.axis_label?.length * 5) ||
                -10,
              20,
            ],
            distance: 0,
            position: ".95fr right",
          },
          ticks: {
            visible: !graphSettings?.more_options?.axis_x?.hide_tricks,
            majorHeight: 10,
            minorHeight: 5,
            minorTicks: 0,
            drawLabels: graphSettings?.more_options?.axis_x?.draw_labels,
            strokeColor: "black",
            insertTicks: false,
            label: {
              visible: true,
              offset: [0, -10],
            },
            ticksDistance:
              graphSettings?.more_options?.axis_x?.ticks_distance || 1,
          },
          lastArrow: graphSettings?.more_options?.axis_x?.max_arrow,
          firstArrow: graphSettings?.more_options?.axis_x?.min_arrow,
        }
      );

      board.current.create(
        "axis",
        [
          [0, 0],
          [0, 1],
        ],
        {
          withLabel: graphSettings?.more_options?.axis_y?.show_axis_label,
          name: graphSettings?.more_options?.axis_y?.axis_label,
          label: {
            position: "rt",
            offset: [10, 0],
          },
          ticks: {
            visible: !graphSettings?.more_options?.axis_y?.hide_tricks,
            majorHeight: 10,
            minorHeight: 5,
            minorTicks: 0,
            drawLabels: graphSettings?.more_options?.axis_y?.draw_labels,
            insertTicks: false,
            label: {
              visible: true,
              offset: [-25, 0],
            },
            ticksDistance:
              graphSettings?.more_options?.axis_y?.ticks_distance || 1,
            strokeColor: "black",
          },
          lastArrow: graphSettings?.more_options?.axis_y?.max_arrow,
          firstArrow: graphSettings?.more_options?.axis_y?.min_arrow,
        }
      );

      board.current.on("down", handleBoardClick);
      tabsMap.current.set(tabIndex, board.current);
      console.log("Board initialized with new settings:", board.current);
      if (graphSettings?.more_options?.background_shapes.length > 0) {
        shapesRenderer(
          board.current,
          graphSettings?.more_options?.background_shapes,
          false,
          graphPoints,
          "shaded",
          graphSettings?.more_options?.layout?.snapto,
          {
            strokeColor: "gray",
            borders: {
              strokeColor: "gray",
            },
            draggable: false,
            fixed: true,
            visibilityOnBoard:
              graphSettings?.more_options?.background_image?.display_points,
          }
        );
        board.current.update();
      }
      if (customGraphOptions?.length > 0) {
        const filteredOptions = customGraphOptions
          .filter((option) => option.tab === activeTabIndex)
          .filter((option) => !option.hasParent);
        console.log(
          "Filtered Options for Tab2:",
          activeTabIndex,
          filteredOptions
        );
        const options = { visibilityOnBoard: true };
        const activeBoard = tabsMap.current.get(activeTabIndex);

        if (activeBoard) {
          shapesRenderer(
            activeBoard,
            filteredOptions,
            true,
            graphPoints,
            "draw",
            graphSettings?.more_options?.layout?.snapto,
            options
          );
          activeBoard.update();
        }
      }
    }
  };
  const updateBoardProperties = () => {
    if (!board.current) return;

    // Remove existing grid and axes
    board.current.objectsList
      .filter((obj: any) => obj.elType === "axis" || obj.elType === "grid")
      .forEach((obj: any) => board.current.removeObject(obj));

    // Recreate the grid with updated spacing
    board.current.create("grid", [], {
      majorStep: [
        Number(graphSettings?.more_options?.grid?.x_distance) || 1,
        Number(graphSettings?.more_options?.grid?.y_distance) || 1,
      ],
    });

    // Recreate X-Axis
    board.current.create(
      "axis",
      [
        [0, 0],
        [1, 0],
      ],
      {
        name: graphSettings?.more_options?.axis_x?.axis_label,
        withLabel: graphSettings?.more_options?.axis_x?.show_axis_label,
        label: {
          offset: [
            -(graphSettings?.more_options?.axis_x?.axis_label?.length * 5) ||
              -10,
            20,
          ],
          distance: 0,
          position: ".95fr right",
        },
        ticks: {
          visible: !graphSettings?.more_options?.axis_x?.hide_tricks,
          majorHeight: 10,
          minorHeight: 5,
          minorTicks: 0,
          drawLabels: graphSettings?.more_options?.axis_x?.draw_labels,
          strokeColor: "black",
          insertTicks: false,
          label: { visible: true, offset: [0, -10] },
          ticksDistance:
            graphSettings?.more_options?.axis_x?.ticks_distance || 1,
        },
        lastArrow: graphSettings?.more_options?.axis_x?.max_arrow,
        firstArrow: graphSettings?.more_options?.axis_x?.min_arrow,
      }
    );

    // Recreate Y-Axis
    board.current.create(
      "axis",
      [
        [0, 0],
        [0, 1],
      ],
      {
        name: graphSettings?.more_options?.axis_y?.axis_label,
        withLabel: graphSettings?.more_options?.axis_y?.show_axis_label,
        label: { position: "rt", offset: [10, 0] },
        ticks: {
          visible: !graphSettings?.more_options?.axis_y?.hide_tricks,
          majorHeight: 10,
          minorHeight: 5,
          minorTicks: 0,
          drawLabels: graphSettings?.more_options?.axis_y?.draw_labels,
          insertTicks: false,
          label: { visible: true, offset: [-25, 0] },
          ticksDistance:
            graphSettings?.more_options?.axis_y?.ticks_distance || 1,
          strokeColor: "black",
        },
        lastArrow: graphSettings?.more_options?.axis_y?.max_arrow,
        firstArrow: graphSettings?.more_options?.axis_y?.min_arrow,
      }
    );

    board.current.update();
  };

  useEffect(() => {
    return () => {
      if (board.current) {
        board.current.off("down", handleBoardClick);
      }
    };
  }, []);
  useEffect(() => {
    graphShapesRef.current = customGraphOptions;
    return () => {
      graphShapesRef.current = [];
    };
  }, [customGraphOptions]);
  useEffect(() => {
    console.log("Active Tab Index:", activeTabIndex);
    console.log("Tabs Map:", tabsMap.current);

    // Check if a board instance exists for the active tab
    if (!tabsMap.current.has(activeTabIndex)) {
      // Initialize a new board for the active tab
      initializeBoard(activeTabIndex);
    }

    // Render shapes for the active tab
    if (customGraphOptions?.length > 0) {
      const filteredOptions = customGraphOptions
        .filter((option) => option.tab === activeTabIndex)
        .filter((option) => !option.hasParent);
      console.log("Filtered Options for Tab:", activeTabIndex, filteredOptions);
      const options = { visibilityOnBoard: true };
      const activeBoard = tabsMap.current.get(activeTabIndex);

      if (activeBoard) {
        shapesRenderer(
          activeBoard,
          filteredOptions,
          true,
          graphPoints,
          "draw",
          graphSettings?.more_options?.layout?.snapto,
          options
        );
        activeBoard.update();
      }
    }
  }, [activeTabIndex]);
  useEffect(() => {
    return () => {
      tabsMap.current.forEach((boardInstance) => {
        boardInstance.removeChild(boardInstance);
      });
      tabsMap.current.clear();
    };
  }, []);
  useEffect(() => {
    boardRef.current = graphRef.current[activeTabIndex];
    return () => {
      boardRef.current = null;
    };
  }, [activeTabIndex]);

  useEffect(() => {
    selectedButtonRef.current = selectedButton;
  }, [selectedButton]);
  useEffect(() => {
    deleteStartRef.current = deleteStart;
  }, [deleteStart]);

  useEffect(() => {
    if (currentQuestion) {
      console.log(currentQuestion, "currentQuestionData");
      setGraphSettings(currentQuestion);
    }
    return () => {
      setGraphSettings(data);
    };
  }, [currentQuestion]);

  useEffect(() => {
    if (labelState.element) {
      const x = labelState?.x;
      const y = labelState?.y;
      setLabelPosition({ x, y });
    }
  }, [labelState.element]);

  useEffect(() => {
    // setHasGraphOptions(customGraphOptions.length > 0);
    if (questiontype === "preview") return;

    const storeData = {
      ...graphSettings,
      question: graphSettings?.question,
      correct_answer: {
        valid_response: {
          ...graphData?.correct_answer?.valid_response,
          tools: optionItems?.toolbar?.tools,
          shapes: customGraphOptions?.filter((o) => o.tab === 0) || [],
        },
        alt_responses: graphData?.correct_answer?.alt_responses
          ?.map((res, i) => ({
            ...res,
            tools: optionItems?.toolbar?.tools,
            shapes: customGraphOptions?.filter((o) => o.tab === i + 1) || [],
          }))
          .filter((o) => o.shapes.length > 0),
      },
      options: {
        canvas: { ...graphSettings?.options?.canvas },
        toolbar: {
          ...graphSettings?.options?.toolbar,
          tools: optionItems?.toolbar?.tools,
          controls: graphSettings?.options?.toolbar?.controls,
        },
      },
      more_options: {
        ...graphSettings?.more_options,
        background_shapes: graphSettings?.more_options?.background_shapes,
      },
    };
    // @ts-ignore
    if (questiontype === "add") {
      // console.log("Second dispatch", questiontype);
      dispatch(setKeyValue({ key: "graph", subKey: "", value: storeData }));
      return;
    } else if (questiontype === "edit") {
      // console.log("Third dispatch", questiontype);
      dispatch(EditSubQues(storeData));
      return;
    } else if (questiontype === "shaded") {
      // setBackgroundShapes(customGraphOptions);
      const objectData = {
        ...currentQuestion,
        more_options: {
          ...data?.more_options,
          background_shapes: customGraphOptions,
        },
        options: {
          canvas: optionItems?.canvas,
          toolbar: {
            ...optionItems?.toolbar,
            tools: data?.options?.toolbar?.tools,
            controls: data?.options?.toolbar?.controls,
          },
        },
      };
      // console.log("Forth dispatch", questiontype);
      dispatch(setKeyValue({ key: "graph", subKey: "", value: objectData }));
    }
  }, [customGraphOptions, dispatch, questiontype]);

  useEffect(() => {
    if (questiontype === "preview" && board.current) {
      const src = currentQuestion;
      if (
        !src?.correct_answer?.valid_response.shapes ||
        !src?.correct_answer?.alt_responses
      )
        return;
      if (
        src?.correct_answer?.valid_response.shapes.length > 0 ||
        src?.correct_answer?.alt_responses.length > 0
      ) {
        const validShapes = src?.correct_answer?.valid_response.shapes;
        const altShapes = src?.correct_answer?.alt_responses.flatMap(
          (response) => response.shapes
        );
        const allShapes = [...validShapes, ...altShapes].filter(
          (shape) => !shape.hasParent
        );
        const answers = allShapes.map((shape) => ({
          ...shape,
          options: { ...shape.options, strokeColor: "green" },
        }));
        const options = { visibilityOnBoard: showAnswer };
        shapesRenderer(
          board.current,
          answers,
          false,
          graphPoints,
          "preveiw",
          graphSettings?.more_options?.layout?.snapto,
          options
        );
        if (!showAnswer) {
          const boardOjectMock = [...board.current?.objectsList];
          boardOjectMock.forEach((option: any) => {
            if (answers.includes(option.id)) {
              board.current?.removeObject(option);
            }
          });
          board.current?.update();
          return;
        }
      }
    }
  }, [showAnswer, questiontype]);

  useEffect(() => {
    if (altTabs?.length > watchedFields?.length) {
      //@ts-nocheck
      const deletedIndex = altTabs.findIndex(
        (tab) => !watchedFields.find((field) => field.tabId === tab.tabId)
      );
      if (deletedIndex !== -1) {
        setCustomGraphOptions((prevData) => {
          const filteredData = prevData
            .filter((o) => o.tab !== deletedIndex + 1)
            .map((o) => {
              return {
                ...o,
                tab:
                  o.tab === 0
                    ? 0
                    : o.tab >= deletedIndex + 1
                    ? o.tab - 1
                    : o.tab,
              } as any;
            });
          return filteredData;
        });
      }
    }
  }, [watchedFields]);

  const handleAddingNewAlternateAnswer = () => {
    setValue("correct_answer.alt_responses", [...fields, DEFAULT_ALT_ANSWER]);
    setGraphData({
      ...graphData,
      correct_answer: {
        ...graphData.correct_answer,
        alt_responses: [
          ...graphData.correct_answer.alt_responses,
          DEFAULT_ALT_ANSWER,
        ],
      },
    });
    //@ts-ignore
    setAltTabs([...fields, DEFAULT_ALT_ANSWER]);
  };

  const handlePointsChange = (value: string, index: number) => {
    const numericScore = parseFloat(value);
    if (isNaN(numericScore)) return;

    const newGraphData = JSON.parse(JSON.stringify(graphData));
    if (index === 0) {
      newGraphData.correct_answer.valid_response.score = numericScore;
      setValue("correct_answer.valid_response.score", numericScore);
    } else if (index <= newGraphData.correct_answer.alt_responses.length) {
      newGraphData.correct_answer.alt_responses[index - 1].score = numericScore;
      setValue(`correct_answer.alt_responses.${index - 1}.score`, numericScore);
    }
    setGraphData(newGraphData);
  };

  const getScoringValue = (index: number): number => {
    if (index === 0) {
      return graphData.correct_answer.valid_response.score;
    }
    return graphData.correct_answer.alt_responses?.[index - 1]?.score ?? 0;
  };
  const handleMovePoints = () => {};
  const handleUndo = () => {
    if (graphShapesRef.current.length > 0) {
      const lastItem = graphShapesRef.current.at(-1); // Get the last item from customGraphOptions
      setRedoData((prevRedoData) => [...prevRedoData, lastItem]); // Add it to redoData

      let undoId;
      let index;
      for (let i = graphShapesRef.current.length - 1; i >= 0; i--) {
        const item = graphShapesRef.current[i];
        if (item.tab === activeTabIndex) {
          undoId = item.id;
          index = item.drawKey.id;
          break;
        }
      }
      for (let i = board.current?.objectsList.length - 1; i >= 0; i--) {
        const item = board.current?.objectsList[i] as any;
        console.log(item, "item", undoId);
        if (index === item.id) {
          board.current?.removeObject(item);
          break;
        }
      }
      setCustomGraphOptions(
        (prevData) => prevData.filter((o) => o.id !== undoId) // Remove the item from customGraphOptions
      );
    }
  };

  const handleRedo = () => {
    if (redoData.length > 0) {
      const lastRedoItem = redoData.at(-1);
      console.log(lastRedoItem, "lastRedoItem");
      shapesRenderer(
        board.current,
        [lastRedoItem],
        false,
        graphPoints,
        "draw",
        graphSettings?.more_options?.layout?.snapto,
        {
          strokeColor: "blue",
          borders: {
            strokeColor: "blue",
          },
          draggable: true,
          fixed: false,
          visibilityOnBoard:
            graphSettings?.more_options?.background_image?.display_points,
        }
      );
      setCustomGraphOptions((prevData) => [...prevData, lastRedoItem]);
      setRedoData((prevRedoData) => prevRedoData.slice(0, -1));
    }
  };

  const handleReset = () => {
    setBoardResetting(true);
    setCustomGraphOptions((prevState) => {
      // setBoardResetting(false)
      const filteredData = prevState.filter(
        (option) => option.tab !== activeTabIndex
      );
      const shapes = prevState
        .filter((option) => option.tab === activeTabIndex)
        .map((option) => {
          if (option.subElementIds?.segments) {
            return [
              ...option.subElementIds.segments.map((s) => s.id),
              ...option.subElementIds.pointsShapes.map((s) => s.id),
              option.drawKey.id,
            ];
          }
          return option.drawKey.id;
        });
      console.log(shapes, "shapesForZReset");
      const objectsListCopy = [...board.current.objectsList];
      objectsListCopy.forEach((shape: any) => {
        console.log(shape, "shape");
        if (shapes.flat().includes(shape.id)) {
          board.current.removeObject(shape);
        }
      });
      return filteredData;
    });
    setRedoData([]);
    setUserFinishedDrawing(true);
    graphPoints.current = [];
    setActiveShape(false);
    board.current.update();
    graphShapesRef.current = graphShapesRef.current.filter(
      (shape) => shape.tab !== activeTabIndex
    );
  };
  const handleDelete = () => {
    // setDeleteStart(!deleteStart);
    setDeleteStart(true);
  };

  const handleSettingLabel = (e: any) => {
    if (e.key === "Enter" || e.type === "blur") {
      if (labelState?.element) {
        labelState.element.setAttribute({ name: labelValue, withLabel: true });
      }
      setCustomGraphOptions((prevData) => {
        const objInBoard = graphShapesRef.current.find(
          (element) => element.id === labelState.selectedObject.id
        );
        console.log(objInBoard, "objInBoardInLabel");
        const newObject = getNewLabelObjectState(
          objInBoard.type,
          labelState.isChild,
          objInBoard,
          labelState.element,
          labelValue
        );
        console.log(newObject, "objectSent");
        const filteredData = graphShapesRef.current.filter(
          (element) => element.id !== labelState.selectedObject.id
        );
        // console.log(newObject, "objectSent");
        return [...filteredData, newObject];
      });
      setLabelValue("");
      setActiveShape(false);
    }
  };

  const buttons = [
    {
      label: "Undo",
      icon: <IoIosUndo className="mr-1" />,
      action: handleUndo,
      isDisabled: !hasGraphOptions,
    },
    {
      label: "Redo",
      icon: <IoIosRedo className="mr-1" />,
      action: handleRedo,
      isDisabled: !redoData?.length,
    },
    {
      label: "Reset",
      icon: <RxReset className="mr-1" />,
      action: handleReset,
      isDisabled: graphShapesRef?.current?.length === 0,
    },
    {
      label: "Delete",
      icon: <IoIosClose className="mr-1" />,
      action: handleDelete,
      isDisabled: graphShapesRef?.current?.length === 0,
    },
  ];
  const handleBoardClick = (event: MouseEvent) => {
    event.stopPropagation();
    console.log(selectedButtonRef.current, "BoardButton");
    const coords = board.current.getUsrCoordsOfMouse(event);
    const clickedObject = board.current.getAllObjectsUnderMouse(event) as any;
    const x = Math.round(coords[0]);
    const y = Math.round(coords[1]);
    const newPoint = { x, y };
    if (deleteStartRef.current) {
      console.log("delete start");
      if (!clickedObject.length) return;
      handleDeleteElement(board.current, clickedObject);
      setDeleteStart(false);
      return;
    }
    if (selectedButtonRef.current === "Label") {
      if (!clickedObject.length) return;
      const shapeId = clickedObject[0].id;
      handleLabelClick(
        board.current,
        newPoint,
        shapeId,
        event,
        clickedObject[0]
      );
      return;
    }
    if (
      (clickedObject.length > 0 || selectedButtonRef.current === "Move") &&
      selectedButtonRef.current !== "Label" &&
      selectedButtonRef.current !== "Polygon"
    ) {
      if (!clickedObject.length) return;
      if (clickedObject) {
        const shapeId = clickedObject[0].id;
        clickedObject[0].off("up");
        clickedObject[0].on("up", (e) => {
          const coords = board.current.getUsrCoordsOfMouse(e);
          const newX = Math.round(coords[0]);
          const newY = Math.round(coords[1]);
          const upPoint = { x: newX, y: newY };
          if (graphShapesRef.current.map((i) => i.drawKey.id).includes(shapeId))
            handleMoveShape(board.current, newPoint, upPoint, shapeId);
        });
      }
      return;
    }
    if (selectedButtonRef.current === "Point") {
      handlePointClick(
        board.current,
        x,
        y,
        graphSettings,
        activeTabIndex,
        setCustomGraphOptions,
        selectedButtonRef.current
      );
      return;
    }

    if (buttonsSettings[selectedButtonRef.current]?.max === 2) {
      handleTwoPointsShape(
        board.current,
        newPoint,
        graphSettings,
        activeTabIndex,
        setCustomGraphOptions,
        pointsRef,
        elementsRef,
        selectedButtonRef.current,
        questiontype
      );
      return;
    }

    if (selectedButtonRef.current === "Polygon") {
      handlePolygonClick(
        board.current,
        newPoint,
        graphSettings,
        activeTabIndex,
        setCustomGraphOptions,
        elementsRef,
        selectedButtonRef.current,
        boardResetting,
        questiontype,
        false
      );
      return;
    }
  };
  const handleMoveShape = (board, newPoint, upPoint, shapeId) => {
    console.log(graphShapesRef.current, "customGraphOptions");
    console.log(shapeId, "shapeId");
    const dx = upPoint.x - newPoint.x;
    const dy = upPoint.y - newPoint.y;
    const shapeSelected = graphShapesRef.current.find(
      (s) => s.drawKey.id === shapeId
    );
    if (!shapeSelected && shapeId) {
      setCustomGraphOptions((prevData) => {
        const borderClicked = board.objectsList.find((o) => o.id === shapeId);

        const filteredData = prevData.map((o) => {
          if (o.drawKey.vertices.map((i) => i.id).includes(shapeId)) {
            console.log("method called");
            const isStartPoint =
              o.subElementIds.startPoint.x + dx === upPoint.x &&
              o.subElementIds.startPoint.y + dy === upPoint.y;
            return {
              ...o,
              subElementIds: {
                ...o.subElementIds,
                startPoint: {
                  ...o.subElementIds.startPoint,
                  x: isStartPoint
                    ? o.subElementIds.startPoint.x + dx
                    : o.subElementIds.startPoint.x,
                  y: isStartPoint
                    ? o.subElementIds.startPoint.y + dy
                    : o.subElementIds.startPoint.y,
                },
                endPoint: {
                  ...o.subElementIds.endPoint,
                  x: isStartPoint
                    ? o.subElementIds.endPoint.x + dx
                    : o.subElementIds.endPoint.x,
                  y: isStartPoint
                    ? o.subElementIds.endPoint.y + dy
                    : o.subElementIds.endPoint.y,
                },
                points: o.subElementIds.points.map((point) => {
                  if (point.x === newPoint.x && point.y === newPoint.y) {
                    return {
                      ...point,
                      x: upPoint.x,
                      y: upPoint.y,
                    };
                  } else {
                    return point;
                  }
                }),
              },
            };
          }
          if (borderClicked) {
            const line1X = borderClicked.point1.X();
            const line1Y = borderClicked.point1.Y();
            const line2X = borderClicked.point2.X();
            const line2Y = borderClicked.point2.Y();
            console.log(line1X, line1Y, line2X, line2Y, dx, dy);
            return {
              ...o,
              subElementIds: {
                startPoint: {
                  ...o.subElementIds.startPoint,
                  x: o.subElementIds.startPoint.x,
                  y: o.subElementIds.startPoint.y,
                },
                endPoint: {
                  ...o.subElementIds.endPoint,
                  x: o.subElementIds.endPoint.x,
                  y: o.subElementIds.endPoint.y,
                },
                points: o.subElementIds.points.map((point) => {
                  if (
                    (point.x + dx === line1X && point.y + dy === line1Y) ||
                    (point.x + dx === line2X && point.y + dy === line2Y)
                  ) {
                    console.log("method called 2");
                    return {
                      ...point,
                      x: point.x + dx,
                      y: point.y + dy,
                    };
                  } else {
                    return point;
                  }
                }),
              },
              options: {
                ...o.options,
              },
            };
          }
        });
        return filteredData;
      });
      return;
    }
    if (shapeSelected.drawKey.elType === "point" && !shapeSelected.hasParent) {
      setCustomGraphOptions((prevData) => {
        const filteredData = prevData.map((o) => {
          if (o.drawKey.id === shapeSelected.drawKey.id) {
            return {
              ...o,
              coords: {
                x: upPoint.x,
                y: upPoint.y,
              },
              options: {
                ...o.options,
              },
            };
          }
          return o;
        });
        return filteredData;
      });
      return;
    }
    if (
      shapeSelected.drawKey.elType !== "curve" &&
      shapeSelected.drawKey.elType !== "polygon"
    ) {
      if (!shapeSelected.hasParent) {
        setCustomGraphOptions((prevData) => {
          const filteredData = prevData.map((o) => {
            if (o.drawKey.id === shapeId) {
              return {
                ...o,
                subElementIds: {
                  ...o.subElementIds,
                  startPoint: {
                    ...o.subElementIds.startPoint,
                    x: o.subElementIds.startPoint.x + dx,
                    y: o.subElementIds.startPoint.y + dy,
                  },
                  endPoint: {
                    ...o.subElementIds.endPoint,
                    x: o.subElementIds.endPoint.x + dx,
                    y: o.subElementIds.endPoint.y + dy,
                  },
                },
              };
            }
            return o;
          });
          return filteredData;
        });
      } else {
        setCustomGraphOptions((prevData) => {
          const filteredData = prevData.map((o) => {
            if (o.drawKey.parents.includes(shapeId)) {
              const isStartPoint =
                o.subElementIds.startPoint.x + dx === upPoint.x &&
                o.subElementIds.startPoint.y + dy === upPoint.y;
              return {
                ...o,
                subElementIds: {
                  ...o.subElementIds,
                  startPoint: isStartPoint
                    ? {
                        ...o.subElementIds.startPoint,
                        x: o.subElementIds.startPoint.x + dx,
                        y: o.subElementIds.startPoint.y + dy,
                      }
                    : {
                        ...o.subElementIds.startPoint,
                        x: o.subElementIds.startPoint.x,
                        y: o.subElementIds.startPoint.y,
                      },
                  endPoint: isStartPoint
                    ? {
                        ...o.subElementIds.endPoint,
                        x: o.subElementIds.endPoint.x,
                        y: o.subElementIds.endPoint.y,
                      }
                    : {
                        ...o.subElementIds.endPoint,
                        x: o.subElementIds.endPoint.x + dx,
                        y: o.subElementIds.endPoint.y + dy,
                      },
                },
              };
            }
            return o;
          });
          return filteredData;
        });
      }
      return;
    }
    if (shapeSelected.drawKey.elType === "curve") {
      if (!shapeSelected.hasParent) {
        board.removeObject(shapeSelected.drawKey);
        const newShape =
          shapeSelected.type === "Sine"
            ? drawSine(board, [
                [
                  shapeSelected.subElementIds.startPoint.x + dx,
                  shapeSelected.subElementIds.startPoint.y + dy,
                ],
                [
                  shapeSelected.subElementIds.endPoint.x + dx,
                  shapeSelected.subElementIds.endPoint.y + dy,
                ],
              ])
            : drawParabola(board, [
                [
                  shapeSelected.subElementIds.startPoint.x + dx,
                  shapeSelected.subElementIds.startPoint.y + dy,
                ],
                [
                  shapeSelected.subElementIds.endPoint.x + dx,
                  shapeSelected.subElementIds.endPoint.y + dy,
                ],
              ]);
        setCustomGraphOptions((prevData) => {
          const newShapeObj = {
            ...shapeSelected,
            drawKey: newShape,
          };
          const filteredData = prevData.filter((i) => i.drawKey.id !== shapeId);
          return [...filteredData, newShapeObj];
        });
      }
      return;
    }
    if (shapeSelected.drawKey.elType === "polygon") {
      setCustomGraphOptions((prevData) => {
        const filteredData = prevData.map((o) => {
          if (o.drawKey.id === shapeId) {
            return {
              ...o,
              subElementIds: {
                ...o.subElementIds,
                startPoint: {
                  ...o.subElementIds.startPoint,
                  x: o.subElementIds.startPoint.x + dx,
                  y: o.subElementIds.startPoint.y + dy,
                },
                endPoint: {
                  ...o.subElementIds.endPoint,
                  x: o.subElementIds.endPoint.x + dx,
                  y: o.subElementIds.endPoint.y + dy,
                },
                points: o.subElementIds.points.map((point) => {
                  return {
                    ...point,
                    x: point.x + dx,
                    y: point.y + dy,
                  };
                }),
              },
            };
          }
          return o;
        });
        return filteredData;
      });
    }
  };
  const handleLabelClick = (board, newPoint, shapeId, event, element) => {
    setActiveShape(false);
    const elementCLicked = graphShapesRef.current.find(
      (item) => item.drawKey.id === shapeId
    );
    const parent = graphShapesRef.current.find((item) =>
      item.drawKey.parents.includes(shapeId)
    );

    if (!elementCLicked) {
      const parentPolygon = graphShapesRef.current.find((item) =>
        item.drawKey?.vertices?.map((i) => i.id).includes(shapeId)
      );
      if (!parentPolygon) return;
      const positions = board
        .getAllUnderMouse(event)
        .find((item) => Array.isArray(item));
      const state = {
        element: element,
        x: positions[0] + board.getMousePosition(event)[0],
        y: positions[1] + board.getMousePosition(event)[1],
        selectedObject: parentPolygon,
        isChild: true,
      };
      console.log(state, "stateSend");
      setLabelState(state);
      setActiveShape(true);
      return;
    }
    if (!elementCLicked.hasParent) {
      const positions = board
        .getAllUnderMouse(event)
        .find((item) => Array.isArray(item));
      const state = {
        element: element,
        x: positions[0] + board.getMousePosition(event)[0],
        y: positions[1] + board.getMousePosition(event)[1],
        selectedObject: elementCLicked,
        isChild: false,
      };
      console.log(state, "stateSendparent");
      setLabelState(state);
    } else {
      console.log(parent, "parent");
      if (parent) {
        const point1Coords = {
          x:
            parent.drawKey?.point1?.X() || parent?.subElementIds?.startPoint?.x,
          y:
            parent.drawKey?.point1?.Y() || parent?.subElementIds?.startPoint?.y,
        };
        const point2Coords = {
          x: parent.drawKey?.point2?.X() || parent?.subElementIds?.endPoint?.x,
          y: parent.drawKey?.point2?.Y() || parent?.subElementIds?.endPoint?.y,
        };

        // Convert to viewport coordinates
        const topLeft = board.getMousePosition(event);
        const viewportCoords1 = {
          x: point1Coords.x + topLeft[0],
          y: point1Coords.y + topLeft[1],
        };
        const viewportCoords2 = {
          x: point2Coords.x + topLeft[0],
          y: point2Coords.y + topLeft[1],
        };

        const state = {
          element: element,
          x: Math.round((viewportCoords1.x + viewportCoords2.x) / 2),
          y: Math.round((viewportCoords1.y + viewportCoords2.y) / 2),
          selectedObject: elementCLicked,
          isChild: true,
        };
        setLabelState(state);
      } else {
        console.log(
          "parent not found",
          graphShapesRef.current.filter((s) => !s.hasParent)
        );
        const parentCurve = graphShapesRef.current
          .filter((s) => !s.hasParent)
          .find(
            (item) =>
              item.subElementIds?.startPoint?.x === elementCLicked.coords.x &&
              item.subElementIds?.startPoint?.y === elementCLicked.coords.y &&
              item.subElementIds?.endPoint?.x === elementCLicked.coords.x &&
              item.subElementIds?.endPoint?.y === elementCLicked.coords.y
          );
        const point1Coords = {
          x: parentCurve?.subElementIds?.startPoint?.x,
          y: parentCurve?.subElementIds?.startPoint?.y,
        };

        // Convert to viewport coordinates
        const topLeft = board.getMousePosition(event);
        const viewportCoords1 = {
          x: elementCLicked.coords.x + topLeft[0],
          y: elementCLicked.coords.y + topLeft[1],
        };
        const state = {
          element: element,
          x: Math.round(viewportCoords1.x),
          y: Math.round(viewportCoords1.y),
          selectedObject: elementCLicked,
          isChild: true,
        };
        setLabelState(state);
      }
    }
    setActiveShape(true);
  };

  const handleDeleteElement = (board, clickedObject) => {
    if (!clickedObject || clickedObject.length === 0) return;

    const elementId = clickedObject[0].id;
    let elementToDelete = graphShapesRef.current.find(
      (item) => item.drawKey.id === elementId
    );

    const findElementsToDelete = (mainElement) => {
      console.log(mainElement, "mainElement");
      let idsToDelete = [mainElement.drawKey.id];

      if (mainElement.type === "Polygon") {
        const children = [
          ...(mainElement.subElementIds.segments || []),
          ...(mainElement.subElementIds.pointsShapes || []),
        ];
        idsToDelete.push(...children.map((s) => s.id));
      } else if (!mainElement.hasParent) {
        console.log(mainElement, "errorHere");
        const children = graphShapesRef.current.filter(
          (item) => item.parentID === mainElement.id
        );
        idsToDelete.push(...children.map((s) => s.drawKey.id));
      } else {
        const parent = graphShapesRef.current.find(
          (item) => item.id === mainElement.parentID
        );
        if (parent) {
          const siblings = graphShapesRef.current.filter(
            (item) => item.parentID === parent.id
          );
          idsToDelete.push(
            parent.drawKey.id,
            ...siblings.map((s) => s.drawKey.id)
          );
        }
      }

      return idsToDelete;
    };

    if (!elementToDelete) {
      const parent = graphShapesRef.current.find(
        (item) => clickedObject[0].descendants?.[item.drawKey.id]
      );

      if (parent) {
        elementToDelete = parent;
      } else {
        const parentPolygon = clickedObject.find(
          (item) => item.elType === "polygon"
        );
        if (parentPolygon) {
          elementToDelete = graphShapesRef.current.find(
            (item) => item.drawKey.id === parentPolygon.id
          );
        }
      }
    }

    if (!elementToDelete) {
      console.warn(" Element not found for deletion.");
      return;
    }

    const idsToDelete = findElementsToDelete(elementToDelete);

    for (let i = board.objectsList.length - 1; i >= 0; i--) {
      let deletionsCount = idsToDelete.length;
      if (deletionsCount > 0) {
        const item = board.objectsList[i];
        if (idsToDelete.includes(item.id)) {
          board.removeObject(item);
          deletionsCount--;
        }
      }
      if (deletionsCount === 0) break;
    }

    setCustomGraphOptions((prevData) =>
      prevData.filter((o) => !idsToDelete.includes(o.drawKey.id))
    );

    board.update();
  };

  return (
    <div className="w-full">
      {questiontype !== "preview" && questiontype !== "shaded" && (
        <ResponseTabs
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          fields={fields}
          onAddAlternative={handleAddingNewAlternateAnswer}
          getScoringValue={getScoringValue}
          onScoreChange={handlePointsChange}
          remove={remove}
        />
      )}
      <div className="flex flex-col items-start justify-between mb-5 rounded-md gap-y-1 w-[800px]">
        {graphSettings?.more_options?.annotation?.title && (
          <div className="text-center text-lg font-medium mt-2 mb-1">
            {graphSettings?.more_options?.annotation?.title}
          </div>
        )}
        <div className="mt-5 flex items-center p-2 justify-between bg-[#eee] rounded-md flex-wrap min-w-full gap-2">
          <div className="flex space-x-2">
            {optionItems?.toolbar?.tools?.map((tool, index) => (
              <button
                key={`${tool}-${index}`}
                type="button"
                className={`mr-2 flex p-[5px] ${
                  customGraphOptionsIndex === index
                    ? "rounded-sm border-[#fa9b31]"
                    : ""
                } border-2`}
                onClick={() => {
                  setSelectedButton(tool);
                  setCustomGraphOptionsIndex(index);
                  handleMovePoints();
                }}
              >
                {tool === "Point" && <TbPoint className="mr-1" />}
                {tool === "Move" && <IoMdMove className="mr-1" />}
                {tool === "Circle" && <FaRegCircle className="mr-1" />}
                {tool}
              </button>
            ))}
          </div>
          <Controls
            buttons={buttons.filter((btn) =>
              graphSettings?.options?.toolbar?.controls?.includes(btn.label)
            )}
          />
        </div>
        <div
          className="flex items-center justify-between mb-5 rounded-md relative"
          onMouseLeave={() => {
            setUserFinishedDrawing(true);
          }}
          onMouseEnter={() => {
            setUserFinishedDrawing(false);
          }}
        >
          <div
            style={{
              margin: `${graphSettings?.more_options?.layout?.margin || 0}px`,
            }}
          >
            {graphSettings?.more_options?.annotation?.label_top && (
              <div className="text-center font-2 mt-2 mb-1">
                {graphSettings?.more_options?.annotation?.label_top}
              </div>
            )}
            <div className="basis-full">
              {graphSettings?.more_options?.annotation?.label_left && (
                <div className="text-center font-2 mt-2 mb-1 rotate-90 origin-top-left pr-40">
                  {graphSettings?.more_options?.annotation?.label_left}
                </div>
              )}
              <div
                ref={(el) => {
                  graphRef.current[activeTabIndex] = el; // Correctly assign the current div
                }}
                className="jxgbox border"
                style={{
                  height: `${
                    graphSettings?.more_options?.layout?.height || 800
                  }px`,
                  width: `${
                    graphSettings?.more_options?.layout?.width || 800
                  }px`,
                }}
              ></div>
              {graphSettings?.more_options?.annotation?.label_right && (
                <div className="text-center font-2 mt-4 mb-4 rotate-90 origin-top-right pl-40 translate-x-4">
                  {graphSettings?.more_options?.annotation?.label_right}
                </div>
              )}
            </div>
            {graphSettings?.more_options?.annotation?.label_bottom && (
              <div className="text-center font-2 mt-2 mb-1">
                {graphSettings?.more_options?.annotation?.label_bottom}
              </div>
            )}
          </div>
          {activeShape && (
            <input
              type="text"
              value={labelValue}
              onChange={(e) => setLabelValue(e.target.value)}
              style={{
                position: "absolute",
                top: `${labelPosition.y}px`,
                left: `${labelPosition.x}px`,
                display: labelState.element ? "block" : "none",
              }}
              onKeyDown={(e) => {
                handleSettingLabel(e);
              }}
              onBlur={(e) => handleSettingLabel(e)}
              placeholder="Type then press enter"
              className="border border-gray-300 rounded-md p-2"
            />
          )}
        </div>
      </div>
    </div>
  );
}
