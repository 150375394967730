import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { Stage, Layer, Image as KonvaImage, Line, Circle, Group, Text, Rect } from 'react-konva';
import {useDispatch, useSelector} from 'react-redux';
import { RootState } from '../../../redux/store';
import { CanvasWrapper, CanvasContainer } from './CanvasStyles';
import { UserResponse, ImageAnnotation, Point, Polygon } from './types';
import { isPointInPolygon, getCircleIndicatorPosition } from './geometry';
import Button from './Button';
import ResponseBox from './ResponseBox';
import { getLabelByStemNumeration } from 'utils/generalUtils';

const CLOSE_THRESHOLD = 10;
const MAX_WIDTH = 2000;
const MAX_HEIGHT = 1000;
const DEFAULT_WIDTH = 1300;
const DEFAULT_HEIGHT = 800;

export interface CanvasPreviewProps {
    currentQuestion: any;
    showAnswer?: boolean;
    parentMode?: string;
    editMode?: boolean;
    currentAnnotations?: Array<{
        id: string;
        coordinates: any;
        label: string;
    }>;
    onAnnotationUpdate?: (annotations: Array<{
        id: string;
        coordinates: any;
        label: string;
    }>) => void;
    disabled?: boolean;
    score?: number;
    studentAnswer?: any[];
    onAnswerChange?: (answers: ValidatedResponse[]) => void;
}

interface ValidatedResponse extends UserResponse {
    isCorrect: boolean | null;
}

const CanvasPreview: React.FC<CanvasPreviewProps> = ({
                                                         currentQuestion,
                                                         showAnswer = false,
                                                         parentMode = 'assessment',
                                                         editMode = false,
                                                         currentAnnotations = [],
                                                         onAnnotationUpdate,
                                                         disabled = false,
                                                         score = 0,
                                                         studentAnswer = [],
                                                         onAnswerChange
                                                     }) => {
    const questionType = "imup";
    const dispatch = useDispatch();
    const selectorImageAnnotation = useSelector(
        (state: RootState) => state?.question?.subQuestions?.[questionType]
    );
    const { isTimerActive } = useSelector(
        (state: RootState) => state.preview || {}
    );

    const imageAnnotation: ImageAnnotation | undefined = useMemo(() => {
        return editMode ? currentQuestion : selectorImageAnnotation;
    }, [editMode, currentQuestion, selectorImageAnnotation]);

    const isInteractionDisabled = useMemo(() => {
        return disabled || (parentMode === 'student' && !isTimerActive);
    }, [disabled, parentMode, isTimerActive]);

    // @ts-ignore
    const imageRef = useRef<KonvaImage>(null);
    const stageRef = useRef(null);
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerSize, setContainerSize] = useState({ width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT });
    const [dimensions, setDimensions] = useState({
        width: DEFAULT_WIDTH,
        height: DEFAULT_HEIGHT
    });
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isImageError, setIsImageError] = useState<boolean>(false);
    const [isAltTextVisible, setIsAltTextVisible] = useState(false);
    const [userResponses, setUserResponses] = useState<ValidatedResponse[]>(() =>
        studentAnswer.map(answer => ({
            ...answer,
            id: `response-${Date.now()}-${Math.random()}`,
            isCorrect: null
        }))
    );
    const [selectedResponseIndex, setSelectedResponseIndex] = useState<number | null>(null);

    const polygons = useMemo(() =>
            imageAnnotation?.correct_answer?.valid_response?.value || [],
        [imageAnnotation]
    );

    const calculateDimensions = useCallback((imgWidth: number, imgHeight: number) => {
        const annotationWidth = DEFAULT_WIDTH;
        const annotationHeight = DEFAULT_HEIGHT;

        if (annotationWidth && annotationHeight) {
            return {
                width: Math.min(annotationWidth, MAX_WIDTH),
                height: Math.min(annotationHeight, MAX_HEIGHT)
            };
        }

        if (!imgWidth || !imgHeight) return { width: Math.max(DEFAULT_WIDTH, 700), height: Math.round((Math.max(DEFAULT_WIDTH, 700) * DEFAULT_HEIGHT) / DEFAULT_WIDTH) };

        let newWidth = imgWidth;
        let newHeight = imgHeight;

        if (newWidth > MAX_WIDTH) {
            const scale = MAX_WIDTH / newWidth;
            newWidth = MAX_WIDTH;
            newHeight = Math.round(newHeight * scale);
        }

        if (newHeight > MAX_HEIGHT) {
            const scale = MAX_HEIGHT / newHeight;
            newHeight = MAX_HEIGHT;
            newWidth = Math.round(newWidth * scale);
        }

        return {
            width: Math.round(newWidth),
            height: Math.round(newHeight)
        };
    }, [imageAnnotation]);


    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setContainerSize({
                    width: containerRef.current.clientWidth,
                    height: containerRef.current.clientHeight
                });
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (imageAnnotation?.image?.source) {
            const img = new window.Image();

            img.onload = () => {
                setIsImageLoaded(true);
                const originalWidth = imageAnnotation.image.width || img.naturalWidth;
                const originalHeight = imageAnnotation.image.height || img.naturalHeight;

                const scaledDimensions = calculateDimensions(originalWidth, originalHeight);
                setDimensions(scaledDimensions);
                setImage(img);
                setIsImageError(false);
            };

            img.onerror = () => {
                setIsImageError(true);
                setIsImageLoaded(false);
                setDimensions({ width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT });
                setImage(null);
            };

            img.src = imageAnnotation.image.source;
        } else {
            setImage(null);
            setIsImageLoaded(false);
            setIsImageError(false);
            setDimensions({ width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT });
        }
    }, [imageAnnotation?.image?.source, calculateDimensions]);

    useEffect(() => {
        if (onAnswerChange) {
            onAnswerChange(userResponses);
        }
    }, [userResponses, onAnswerChange]);

    const validateResponse = useCallback((response: ValidatedResponse) => {
        if (!response.answer.trim()) return false;

        const circlePosition = getCircleIndicatorPosition(response);
        const caseSensitive = currentQuestion?.more_options?.scoring?.case_sensitive ?? false;

        for (const polygon of polygons) {
            const points = polygon.points.map(p => ({ x: p.x, y: p.y }));

            if (isPointInPolygon(circlePosition, points)) {
                const responseAnswer = response.answer.trim();
                const polygonLabel = polygon?.label?.trim();
                if (caseSensitive) {
                    return responseAnswer === polygonLabel;
                } else {
                    return responseAnswer?.toLowerCase() === polygonLabel?.toLowerCase();
                }
            }
        }

        return false;
    }, [polygons, currentQuestion]);

    const updateResponseValidation = useCallback(() => {
        if (!showAnswer) {
            setUserResponses(prev => prev.map(response => ({ ...response, isCorrect: null })));
            return;
        }

        setUserResponses(prev =>
            prev.map(response => ({
                ...response,
                isCorrect: validateResponse(response)
            }))
        );
    }, [showAnswer, validateResponse]);

    useEffect(() => {
        updateResponseValidation();
    }, [showAnswer, updateResponseValidation]);

    const addNewResponse = useCallback(() => {
        if (isInteractionDisabled) return;

        const stageWidth = dimensions.width;
        const newResponse: ValidatedResponse = {
            id: `response-${Date.now()}`,
            position: {
                x: (stageWidth / 2) - 100,
                y: 20,
            },
            answer: "",
            width: 200,
            height: 40,
            isCorrect: null
        };

        setUserResponses(prev => [...prev, newResponse]);
        setSelectedResponseIndex(prev => (prev === null ? prev : prev + 1));
    }, [dimensions.width, isInteractionDisabled]);

    const handleDragMove = useCallback((index: number, e: any) => {
        if (isInteractionDisabled) return;

        const { x, y } = e.target.position();
        setUserResponses(prev => {
            const newResponses = [...prev];
            newResponses[index] = {
                ...newResponses[index],
                position: { x, y },
            };
            return newResponses;
        });
    }, [isInteractionDisabled]);

    const handleGroupClick = useCallback((index: number, e: any) => {
        if (isInteractionDisabled) return;

        e.cancelBubble = true;
        setSelectedResponseIndex(index);
    }, [isInteractionDisabled]);

    const updateResponse = useCallback((index: number, answer: string) => {
        if (isInteractionDisabled) return;

        setUserResponses(prev => {
            const newResponses = [...prev];
            newResponses[index] = {
                ...newResponses[index],
                answer,
            };
            return newResponses;
        });
    }, [isInteractionDisabled]);

    const deleteResponse = useCallback((index: number) => {
        if (isInteractionDisabled) return;

        setUserResponses(prev => prev.filter((_, i) => i !== index));
        setSelectedResponseIndex(null);
    }, [isInteractionDisabled]);

    const clearResponses = useCallback(() => {
        if (isInteractionDisabled) return;

        setUserResponses([]);
        setSelectedResponseIndex(null);
    }, [isInteractionDisabled]);

    const handleStageMouseDown = useCallback((e: any) => {
        if (e.target === e.target.getStage()) {
            setSelectedResponseIndex(null);
        }
    }, []);

    const handleDeleteSelected = useCallback(() => {
        if (isInteractionDisabled || selectedResponseIndex === null) return;

        deleteResponse(selectedResponseIndex);
    }, [deleteResponse, selectedResponseIndex, isInteractionDisabled]);

    const handleDeselect = useCallback(() => {
        setSelectedResponseIndex(null);
    }, []);

    if (!dimensions.width || !dimensions.height) {
        return <div>Loading...</div>;
    }

    return (
        <CanvasWrapper ref={containerRef}>
            <CanvasContainer>
                <Stage
                    ref={stageRef}
                    width={dimensions.width}
                    height={dimensions.height}
                    onMouseDown={handleStageMouseDown}
                >
                    <Layer>
                        {image && isImageLoaded && !isImageError ? (
                            <KonvaImage
                                ref={imageRef}
                                image={image}
                                x={0}
                                y={0}
                                width={dimensions.width}
                                height={dimensions.height}
                                alt={imageAnnotation?.image?.alt}
                            />
                        ) : (
                            <Group>
                                <Rect
                                    x={0}
                                    y={0}
                                    width={dimensions.width}
                                    height={dimensions.height}
                                    fill="#f5f5f5"
                                    stroke="#ddd"
                                    strokeWidth={1}
                                />
                                <Text
                                    text={imageAnnotation?.image?.alt || "No image available"}
                                    x={0}
                                    y={dimensions.height / 2 - 10}
                                    width={dimensions.width}
                                    height={20}
                                    align="center"
                                    fontSize={16}
                                    fill="#666"
                                />
                            </Group>
                        )}

                        {isAltTextVisible && imageAnnotation?.image?.alt && (
                            <Group>
                                <Rect
                                    x={10}
                                    y={10}
                                    width={Math.min(300, dimensions.width - 20)}
                                    height={40}
                                    fill="rgba(0, 0, 0, 0.8)"
                                    cornerRadius={4}
                                    shadowColor="black"
                                    shadowBlur={5}
                                    shadowOffset={{ x: 2, y: 2 }}
                                    shadowOpacity={0.3}
                                />
                                <Text
                                    text={imageAnnotation.image.alt}
                                    x={20}
                                    y={20}
                                    width={Math.min(280, dimensions.width - 40)}
                                    fill="white"
                                    fontSize={14}
                                    wrap="word"
                                    align="left"
                                />
                            </Group>
                        )}

                        {showAnswer && polygons.map((polygon, index) => (
                            <Group key={`polygon-${index}`} draggable={false}>
                                <Line
                                    points={polygon.points.flatMap((point) => [point.x, point.y])}
                                    stroke="blue"
                                    strokeWidth={2}
                                    closed
                                    fill="rgba(0,0,255,0.3)"
                                />
                                {polygon.points.map((point, i) => (
                                    <Circle
                                        key={`point-${i}`}
                                        x={point.x}
                                        y={point.y}
                                        radius={8}
                                        fill="white"
                                        stroke="blue"
                                        strokeWidth={2}
                                    />
                                ))}
                                {polygon.label && (
                                    <Text
                                        text={getLabelByStemNumeration(currentQuestion.more_options, index)}
                                        x={polygon.points[0]?.x || 0}
                                        y={polygon.points[0]?.y - 20 || 0}
                                        fontSize={16}
                                        fill="black"
                                        padding={4}
                                    />
                                )}
                            </Group>
                        ))}

                        {userResponses.map((response, index) => (
                            <ResponseBox
                                key={response.id}
                                response={response}
                                index={index}
                                isSelected={selectedResponseIndex === index}
                                isCorrect={response.isCorrect}
                                MoreOptions={currentQuestion.more_options}
                                onDragMove={handleDragMove}
                                onClick={handleGroupClick}
                                onUpdateResponse={updateResponse}
                                onDelete={deleteResponse}
                                onDeselect={handleDeselect}
                                disabled={isInteractionDisabled}
                            />
                        ))}
                    </Layer>
                </Stage>

                <div className="mt-4 space-y-4">
                    <div className="flex justify-center gap-4">
                        <Button
                            name="Add Response"
                            onClick={addNewResponse}
                            disabled={disabled}
                        />
                        <Button
                            name="Clear All"
                            onClick={clearResponses}
                            disabled={disabled}
                        />
                        {selectedResponseIndex !== null && (
                            <Button
                                name="Delete Selected"
                                onClick={handleDeleteSelected}
                                disabled={disabled}
                            />
                        )}
                    </div>
                </div>
            </CanvasContainer>
        </CanvasWrapper>
    );
};

export default React.memo(CanvasPreview);
