import React, { useEffect, useRef } from "react";
import { useDrag } from "react-dnd";
import DOMPurify from "dompurify";
import { ItemTypes } from "types";
import { X } from "lucide-react";

interface DraggableElementProps {
  label: string;
  onRemove?: () => void;
  showRemove?: boolean; 
  sourceId?: number;
  itemId?: string
}

const DraggableElement: React.FC<DraggableElementProps> = ({ label, onRemove, showRemove,sourceId,itemId }) => {
  const elRef = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.MATCH,
    item: { label, sourceId, itemId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }), [label, sourceId, itemId]);

  useEffect(() => {
    if (elRef.current) {
      elRef.current.innerHTML = DOMPurify.sanitize(label);
    }
  }, [label]);

  return (
    <div
      ref={drag}
      className={`flex items-center justify-between p-2 m-2 w-auto border border-[#4a9d2d] rounded-md bg-[#4a9d2d] text-white cursor-move ${
        isDragging ? "opacity-50" : "opacity-100"
      }`}
    >
      <div ref={elRef} className="flex-grow p-2" />
      
      {/* Show remove button only when showRemove is true */}
      {showRemove && onRemove && (
        <button onClick={onRemove} className="ml-2 p-1 text-white hover:text-red-400">
          <X size={16} />
        </button>
      )}
    </div>
  );
};

export default DraggableElement;