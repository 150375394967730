import React, { Fragment, useEffect, useState } from 'react';
import { EditTokenTypes } from '../../Charts/SubChildren/components/chartquestion.constants';
import { LineSentence, LineWord, Paragraph, Sentence, Word } from "./tokenHiglights.types";
import './tokenHighlight.css';
import { TokenCorrect } from '../styled';
import { stripHtmlTagsAndTrackNewlines } from 'utils/questionUtils';

const EditCorrectAnswer = ({questionData, handleData, activeTab,showAnswer}) => {    
    const initial_template = questionData?.template_response?.initial_template || "";
    const token_type = questionData?.template_response?.token_type;
    const token_templates = activeTab?.value;
    const correct_answer = questionData?.correct_answer?.valid_response?.value?.noneTypeTokens || [];    
    const more_options = questionData?.more_options;
    const fontSize = more_options && more_options?.layout.fontSize ? 
        more_options.layout.fontSize  : 
        '12px';
        const maxNumberOfResponses = more_options && !isNaN(Number(more_options?.layout?.max_selection)) ?
        Number(more_options.layout.max_selection) : 
        -1; // Default to -1 (infinite selections)

    
 const [noneTypeTokens, setNoneTypeTokens] = useState(
    questionData?.template_response?.token_templates?.noneTypeTokens || []
);
useEffect(() => {
    const tokens = questionData?.template_response?.token_templates?.noneTypeTokens || [];
    setNoneTypeTokens(tokens);
}, [questionData?.template_response?.token_templates?.noneTypeTokens]); 
    const toggleParagraphCorrect = (lineId: Number) => {
        const totalNumberOfResponses = token_templates.paragraphs.filter((p: Paragraph) => p?.isCorrect).length;
        
        const newParagraphs = token_templates.paragraphs.map((p: Paragraph) => {
            if(p.lineId == lineId && p.isToken) { 
                if(Boolean(p.isCorrect) === false && maxNumberOfResponses >= 0 && totalNumberOfResponses >= maxNumberOfResponses ){
                    return p 
                }else {
                    return {...p, isCorrect: !p.isCorrect}
                }
            } 
            else return p
        });
        handleData({...token_templates, paragraphs: newParagraphs})
    }
    
    const toggleSentenceCorrect = (lineId: Number, sentenceId: Number) => {
        let totalNumberOfResponses = 0;
        token_templates?.sentences.forEach((ls: LineSentence) => {
            ls.lineSentences.forEach((s: Sentence) => {
                if(Boolean(s?.isCorrect) == true){
                    totalNumberOfResponses += 1;
                }
            })
        })
        const newSentences = token_templates.sentences.map((ls: LineSentence) => {
            if(ls.lineId == lineId){
                return {
                    ...ls, lineSentences: ls.lineSentences.map((s: Sentence) => {
                        if(s.sentenceId == sentenceId && s.isToken) { 
                            if(Boolean(s.isCorrect) === false && maxNumberOfResponses >= 0 && totalNumberOfResponses >= maxNumberOfResponses ) {
                                return s
                            }else{
                                return {
                                    ...s, isCorrect: !s.isCorrect,
                                }
                            } 
                        }
                        else return s
                    })
                }
            }else return ls
        });
        handleData ({...token_templates, sentences: newSentences})
    }
     const toggleWordCorrect = (lineId: Number, wordId: Number) => {
        let totalNumberOfResponses = 0;
        token_templates?.words.forEach((lw: LineWord) => {
            lw.lineWords.forEach((w: Word) => {
                if(Boolean(w?.isCorrect) == true){
                    totalNumberOfResponses += 1;
                }
            })
        });

        const newWords = token_templates.words.map((lw: LineWord) => {
            return lw.lineId == lineId ? {
                ...lw, lineWords: lw.lineWords.map((w: Word) => {
                    if(w.wordId == wordId && w.isToken) { 
                        if(Boolean(w.isCorrect) === false && maxNumberOfResponses >= 0 && totalNumberOfResponses >= maxNumberOfResponses ) {
                            return w
                        }else{
                            return {
                                ...w, isCorrect: !w.isCorrect,
                            }
                        } 
                    }else {
                        return w;
                    }
                })
            } : lw
        });
        handleData({...token_templates, words: newWords});
    };
    useEffect(() => {
        if (maxNumberOfResponses >= 0) {
            const selectedTokens = noneTypeTokens.filter(token => token.isCorrect);
                if (selectedTokens.length > maxNumberOfResponses) {
                const tokensToKeep = selectedTokens.slice(0, maxNumberOfResponses);
                    const updatedTokens = noneTypeTokens.map(token => {
                    const shouldKeep = tokensToKeep.some(t => t.tokenId === token.tokenId);
                    return { ...token, isCorrect: shouldKeep };
                });
    
                setNoneTypeTokens(updatedTokens);
                handleData({
                    ...token_templates,
                    noneTypeTokens: updatedTokens,
                });
            }
        }
    }, [maxNumberOfResponses]); 
    const toggleNoneTypeToken = (tokenText, start, end) => {
        const updatedTokens = noneTypeTokens.map((token) => {
            if (
                token.text === tokenText &&
                token.start === start &&
                token.end === end
            ) {
                if (token.isCorrect) {
                    return {
                        ...token,
                        isCorrect: !token.isCorrect,
                    };
                } else {
                    const totalCorrectTokens = noneTypeTokens.filter(token => token.isCorrect).length;
                    if (maxNumberOfResponses >= 0 && totalCorrectTokens >= maxNumberOfResponses) {
                        return token; 
                    } else {
                        return {
                            ...token,
                            isCorrect: !token.isCorrect,
                        };
                    }
                }
            }
            return token;
        });
    
        setNoneTypeTokens(updatedTokens);
    
        if (JSON.stringify(updatedTokens) !== JSON.stringify(noneTypeTokens)) {
            setNoneTypeTokens(updatedTokens);
    
            handleData({
                ...token_templates,
                noneTypeTokens: updatedTokens,
            });
        }
    };
    function extractTextWithLineBreaks(htmlString) {
        // Create a temporary container to parse the HTML string
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;
      
        // Get all the <h1> elements from the parsed HTML
        const h1Elements = tempElement.querySelectorAll('h1,p');
      
        // Extract the text content of each <h1> and return the text with <br> after each one
        return Array.from(h1Elements)
          .map(h1 => h1.textContent) // Extract text content from each <h1>
          .join('<br>'); // Join each line with a <br> tag
      }
    const decodeHtmlEntities = (text) => {
        const doc = new DOMParser().parseFromString(text, 'text/html');
        return doc.documentElement.textContent;
    };
    const stripHtmlTags = (html: string): string => {
        return html.replace(/<(?!br\s*\/?)[^>]+>/g, '');
    };
    // const strippedTemplate = stripHtmlTags(initial_template);
    // const decodedText = decodeHtmlEntities(strippedTemplate);
    const {strippedText, positions} = stripHtmlTagsAndTrackNewlines(initial_template);
    
    const renderInitialTemplate = () => {
        const cleanedText = String(noneTypeTokens?.[0]?.text)?.split('\n') // Split by new lines
        .filter(line => line.trim() !== '') // Remove blank or whitespace-only lines
        .join('\n');
        console.log('decodedText.split',cleanedText ,strippedText ,cleanedText==strippedText);

        return (
            <div id="text-container">
                {noneTypeTokens.length === 0 ? (
                    <p dangerouslySetInnerHTML={{ __html: initial_template }} />
                ) : (
                    <div>
                        {strippedText.split('').map((char, index) => {
                            const token = noneTypeTokens.find(
                                (t) => index >= t.start && index < t.end
                            );

                            if (token && index === token.start) {
                                const normalizeString = (str) => str.replace(/\s+/g, ' ').trim();
                                const normalizedString1 = normalizeString(strippedText.substring(token.start, token.end));
const normalizedString2 = normalizeString(token?.text);

// Check if the strings are the same
const areStringsEqual = normalizedString1 === normalizedString2;
const isString1SingleLine = strippedText?.substring(token.start, token.end)?.split('\n').length === 1;
                                const isHighlighted = correct_answer.some(
                                    (item) => item.tokenId === token.tokenId && item.isCorrect
                                );

                                return (
                                    <Fragment
                                        key={index}
                                    >
                                        {
                                            
                                            positions.includes(index) && 
                                            <br />
                                            
                                        }
                                        <TokenCorrect
                                            key={token.tokenId}
                                            isSelected={showAnswer || questionData?.id ? isHighlighted : token.isCorrect}
                                            isClickable={token.isToken}
                                            fontSize={fontSize}
                                            showAnswer={showAnswer}
                                            userSelected={token.isCorrect}
                                            className="hover:opacity-40"
                                            onClick={() => {
                                                if (Boolean(token.isToken)) {
                                                    toggleNoneTypeToken(token.text, token.start, token.end);
                                                }
                                            }}
                                        >
                                            {
                                        token.start===0 && token.end===token.text.length && cleanedText==strippedText && noneTypeTokens.length===1?  
                                         <span dangerouslySetInnerHTML={{ __html: extractTextWithLineBreaks(token?.htmlText )}} />
                                         :
                                         areStringsEqual?
                                         <span dangerouslySetInnerHTML={{ __html:isString1SingleLine?token?.htmlText:extractTextWithLineBreaks(token?.htmlText) +(index===0?'<br/>':'')}} />
                                           :
                                         strippedText.substring(token.start, token.end)
                                        }
                                        </TokenCorrect>
                                    </Fragment>
                                );
                            }

                            return token ? null : (
                                <Fragment 
                                    key={index}
                                >
                                    {
                                        positions.includes(index) && 
                                        <br />
                                    }
                                    {char}
                                </Fragment>)
                        })}
                    </div>
                )}
            </div>
        );
    };
    
    
    return (
        <div 
            className='my-4 rounded-sm bg-gray-200 p-2 overflow-x-scroll'
            style={{
                fontSize, 
                lineHeight: ["xx-large", "x-large"].includes(fontSize) ? '2rem' : '1.5rem',
            }}
        >
            {
                token_type === EditTokenTypes.None && (
                    <div className='px-2'>
                        {renderInitialTemplate()}
                    </div>
                )
            }

            {
                token_type === EditTokenTypes.Paragraph &&
                Array.isArray(token_templates?.paragraphs) &&
                token_templates?.paragraphs.map((p : Paragraph) => {
                    return(
                        <React.Fragment
                            key={p.lineId.toString()}
                        >
                           <TokenCorrect
                                isSelected={p.isCorrect}
                                isClickable={p.isToken}
                                showAnswer={showAnswer}
                                userSelected={p?.userSelected}
                                fontSize={fontSize}
                                onClick={() => {
                                    if(Boolean(p.isToken) === true){
                                        toggleParagraphCorrect(p.lineId)
                                    }
                                }}
                            >
                                {decodeHtmlEntities(stripHtmlTags(p.lineValue))}

                            </TokenCorrect>
                            <br />
                        </React.Fragment>
                    )
                })
            }

            {
                token_type === EditTokenTypes.Sentence &&
                Array.isArray(token_templates?.sentences) &&
                token_templates?.sentences.map((ls : LineSentence) => {
                    return(
                        <p
                            key={ls.lineId.toString()}
                            className='mb-2'
                        >
                           {
                            Array.isArray(ls?.lineSentences) && 
                            ls.lineSentences.map((s: Sentence) => {
                                return(
                                    <TokenCorrect
                                        fontSize={fontSize}
                                        key={s.sentenceId.toString()}
                                        showAnswer={showAnswer}
                                        isSelected={s.isCorrect}
                                        isClickable={s.isToken}
                                        userSelected={s.userSelected}
                                        className=' hover:opacity-40'
                                        onClick={() => {
                                            if(Boolean(s?.isToken) === true){
                                                toggleSentenceCorrect(ls.lineId, s.sentenceId)}
                                            }
                                        }
                                    >
                                              {decodeHtmlEntities(stripHtmlTags(s.sentenceValue))}
                                             </TokenCorrect>
                                )
                            })
                           } 
                        </p>
                    )
                })
            }

            {
                token_type === EditTokenTypes.Word &&
                Array.isArray(token_templates?.words) &&
                token_templates?.words.map((lw : LineWord) => {

                    return(
                        <p
                            key={lw.lineId.toString()}
                            className='mb-2'
                        >
                           {
                            Array.isArray(lw?.lineWords) && 
                            lw.lineWords.map((w: Word) => {        

                                return(
                                    <TokenCorrect
                                        fontSize={fontSize}
                                        showAnswer={showAnswer}
                                        key={w.wordId.toString()}
                                        isSelected={w.isCorrect}
                                        isClickable={w.isToken}
                                        userSelected={w.userSelected}
                                        className=' hover:opacity-40'
                                        onClick={() => {
                                            if(Boolean(w?.isToken) === true){
                                                toggleWordCorrect(lw.lineId, w.wordId)}
                                            }
                                        }
                                    >
                                        {decodeHtmlEntities(stripHtmlTags(w.wordValue))}

                                    </TokenCorrect>
                                )
                            })
                           } 
                        </p>
                    )
                })
            }
        </div>
    );
};

export default EditCorrectAnswer;