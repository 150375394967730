import { useMemo } from 'react';
import { defaultGriddedConfig } from '../AnswerTabs/config';
import { GriddedConfig } from './types';

export const useGridConfig = (question: any): GriddedConfig => {
    return useMemo(() => {


        return {
            columns: question?.more_options && ![null, NaN].includes(question?.more_options?.layout?.numbers?.columns) ? question?.more_options?.layout?.numbers?.columns : defaultGriddedConfig?.columns,
            range: {
                min: question?.more_options && ![null, NaN].includes(question?.more_options?.layout?.numbers?.range?.min) ? question?.more_options?.layout?.numbers?.range?.min : defaultGriddedConfig?.range?.min ,
                max: question?.more_options && ![null, NaN].includes(question?.more_options?.layout?.numbers?.range?.max) ? question?.more_options?.layout?.numbers?.range?.max : defaultGriddedConfig?.range?.max
            },
            options: {
                plusMinus: question?.more_options?.layout?.features?.plusMinus ?? defaultGriddedConfig?.options?.plusMinus,
                fractionSlash: question?.more_options?.layout?.features?.fractionSlash ?? defaultGriddedConfig?.options?.fractionSlash,
                fixedDecimal: question?.more_options?.layout?.features?.fixedDecimal ?? defaultGriddedConfig?.options?.fixedDecimal,
                floatingDecimal: question?.more_options?.layout?.features?.floatingDecimal ?? defaultGriddedConfig?.options?.floatingDecimal,
                decimalColumn: question?.more_options?.layout?.decimalColumn ?? Math.floor((question?.more_options?.layout?.decimalColumn || defaultGriddedConfig?.options?.decimalColumn) / 2)
            },
            fontSize: question?.more_options?.layout?.fontSize || defaultGriddedConfig?.fontSize,
            scoring: {
                maxScore: question?.more_options?.layout?.scoring?.maxScore ?? defaultGriddedConfig?.scoring?.maxScore,
                partialCredit: question?.more_options?.layout?.scoring?.partialCredit ?? defaultGriddedConfig?.scoring?.partialCredit
            }
        };
    }, [question]) as GriddedConfig;
};
