import React, { useEffect, useRef, useState } from "react";
import { QuestionContent } from "./styled";
import {
  BlockTitle,
  FullWidthContainer,
  LeftContent,
  RightContent,
} from "components/styled";
import ButtonLink from "components/ButtonLink";
import { useDispatch } from "react-redux";
import { setTypeQuestionOpenInEditMode } from "../../../redux/slices/EditModeSlice";
import { useNavigate } from "react-router-dom";
import { setClosePreview } from "redux/slices/PreviewSlice";
import QuestiontypeModal from "components/QuestiontypeModal";

import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import CustomJoditEditor from "components/CustomJoditEditor/ConfigMath";

import { createMathliveForMath } from "components/CustomJoditEditor/ConfigMath/mathLiveForMath";
import SpecialCharacterModal from "components/CustomJoditEditor/ConfigMath/SpecialCharacters";
import { Jodit } from "jodit-react";

interface PreviewMathEssayProps {
  currentQuestion: {
    id: number;
    more_options: {
      metadata: {
        distractor_rationale: string;
        rubric_reference: string;
        sample_answer: string;
        acknowledgements: string;
        stimulus_review: string;
        instructor_stimulus: string;
      };
      ui_style: {
        type: "";
        showHints: false;
        keyboard_below_response_area: false;
        horizontal_layout: false;
        fontsize: string;
        min_height: string;
        max_height: string;
        disable_auto_link: string;
        placeholder: string;
        submit_over_limit: boolean;
        spellcheck: boolean;
        is_math?: boolean;
      };
    };
    question: string;
    options: {
      content: string;
      formatting_options: string[];
      max_length: number;
      show_word_limit: string;
      show_word_count: false;
    };
    type: string;
  };
  showAnswer?: any;
  setShowAnswers?: any;
  parentMode?: string;
  editMode?: boolean;
}

const PreviewMathEssayWithText: React.FC<PreviewMathEssayProps> = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = "assessment",
  editMode = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tokenUser = useSelector(
    (state: RootState) => state.assessments.config.tokenUser
  );
  const specialCharacters = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["mewrt"] as any)?.more_options
        ?.character_map
  )
  // Access the token from the state
  const localToken = localStorage.getItem("token");
  // Determine upload URL and headers
  const defaultApiUrl = process.env.REACT_APP_API_URL || "";
  const obeAppApiUrl = process.env.REACT_APP_OBE_APP_API_URL || "";
  const headers: Record<string, string> = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    ...(tokenUser && !localToken
      ? { Authorization: `Bearer ${tokenUser}` }
      : { Authorization: `Bearer ${localToken}` }),
  };
  const autolink = currentQuestion?.more_options?.ui_style?.disable_auto_link;
  const [isMounted, setIsMounted] = useState(false);
  const maxWords = currentQuestion?.options?.max_length || 0;
  const minHeight = currentQuestion.more_options?.ui_style?.min_height || 200;
  const maxHeight = currentQuestion.more_options?.ui_style?.max_height || 200;
  const placeholder = currentQuestion.more_options?.ui_style?.placeholder || "";
  const uploadUrl =
    localToken || !tokenUser
      ? `${defaultApiUrl}/api/formupload`
      : `${obeAppApiUrl}/formupload`;
  const formattingOptions: string[] =
    currentQuestion?.options?.formatting_options ?? [];
    const editorInstanceRef = useRef<Jodit | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCharaModalOpen, setCharaModalOpen] = useState(false);

  const uniqueButtons = new Set<string>(formattingOptions);
  const handleFileUpload = async (file, editor) => {
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);

      const response = await axios.post(uploadUrl, formData, { headers });

      if (response.status) {
        editor.s.insertHTML(
          `<img src="${response.data.item.src}" alt="Uploaded Image"/>`
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const [content, setContent] = useState<string>(
    currentQuestion?.options.content || ""
  );

  const [isOverLimit, setIsOverLimit] = useState(false);
  const isSpecialChar = useSelector((state: RootState) =>
    state.question.subQuestions?.["mewrt"]?.options?.formatting_options.includes("specialCharacters"))
  const customButtons = [
    {
      name: "uploadImage",
      iconURL:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy_ZxV2ImKF9V0GRXWBfOTuY76TfKqoDa-O4zb7_lUrINJGSLY&s", // URL for your custom icon
      exec: (editor) => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.click();
        input.onchange = () => {
          const file = input.files[0];
          if (file) {
            console.log(file);
            handleFileUpload(file, editor);
          }
        };
      },
    },
    {
      name: "math",
      iconURL: "https://img.icons8.com/ios/50/000000/sigma.png",
      exec: (editor) => {
        const button = createMathliveForMath(editor,"prevQuesTeacher");
        button.exec();
      },
    },
    {
      name: "Response",
      tooltip: "Response",
      exec: (editor) => {
        const span = document.createElement("span");
        span.style.border = "1px solid black";
        span.style.padding = "5px"; // Add padding for spacing
        span.style.userSelect = "none";
        span.style.pointerEvents = "none";
        span.style.backgroundColor = "rgb(238, 238, 238)";
        span.contentEditable = "false";
        span.innerHTML = "<b>Response</b>";
        span.id = "btnresponse";

        editor.s.insertHTML(span.outerHTML);
      },
    },
  ];

  const setEditorInstance = (editorInstance: any) => {
    editorInstanceRef.current = editorInstance;
  };
  
  const updatedButtons = customButtons
    .filter(
      (button) =>
        typeof button === "string" ||
        uniqueButtons.has((button as { name: string }).name)
    )
    .filter(
      (button, index, self) =>
        typeof button === "string" ||
        self.findIndex(
          (b) =>
            typeof b !== "string" &&
            (b as { name: string }).name === (button as { name: string }).name
        ) === index
    );
  const instructorStimulus =
    currentQuestion.more_options?.metadata?.instructor_stimulus;
  let getAllItems = updatedButtons.concat(formattingOptions as any);
  const namesWithObjects = new Set(
    getAllItems
      .filter((item) => typeof item === "object" && item !== null)
      .map((item) => item.name)
  );

  const filteredItems = getAllItems.filter((item) => {
    if (typeof item === "object" && item !== null) {
      return true;
    }
    if (typeof item === "string" && !namesWithObjects.has(item)) {
      return true;
    }
    return false;
  });
  const openSpecialCharacterModal = (editor) => {
    setEditorInstance(editor);
    setCharaModalOpen(true);
  };
  const showWordCount = currentQuestion?.options?.show_word_count;
  const joditConfig = {
    minHeight: minHeight,
    maxHeight: maxHeight,
    placeholder: placeholder,
    readonly: editMode,
    buttons: [
      ...filteredItems,
      isSpecialChar ?
      {
        name: "@",
        tooltip: "Insert Special Character",
        exec: openSpecialCharacterModal, // Use the function correctly
      } : []
    ],
    limitWords: maxWords,
    showWordsCounter: showWordCount,
    showCharsCounter: false,
    isSpecialChar:isSpecialChar
  };

  useEffect(() => {
    setIsMounted(true);
    if (editorInstanceRef.current) {
      console.log("Editor instance is ready:", editorInstanceRef.current);
  }
    if (autolink) {
      document.body.classList.add("hideJoditPopup");
    } else {
      document.body.classList.remove("hideJoditPopup");
    }
 
  }, [autolink,editorInstanceRef.current]);

  if (!isMounted) return null;

  const insertCharacter = (char: string) => {
    if (!editorInstanceRef.current) {
        console.error("Editor instance is not available.");
        return;
    }
    editorInstanceRef.current.selection.insertHTML(char); // Insert the character
};


  const fontSize = currentQuestion?.more_options?.ui_style?.fontsize;

  const fontSizeMapping = {
    small: "12px",
    medium: "14px",
    large: "17px",
    "x-large": "20px",
    "xx-large": "24px",
  };
  const mappedFontSize = fontSizeMapping[fontSize] || "14px";

  const getCleanWordCount = (htmlContent: string) => {
    const cleanContent = htmlContent
      .replace(/<br\s*\/?>/g, " ")
      .replace(/<\/?p>/g, " ")
      .trim();

    const words = cleanContent.split(/\s+/).filter((word) => word !== "");
    return { cleanContent, wordCount: words.length };
  };

  const handleEditorChange = (newContent: string) => {
    let updatedContent = newContent;

    const { wordCount } = getCleanWordCount(updatedContent);
    setIsOverLimit(wordCount >= maxWords);

    setContent(updatedContent);
  };

  const handleEditClick = () => {
    dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const { wordCount } = getCleanWordCount(content);

  return (
    <QuestionContent
      parentMode={parentMode === "question"}
      style={{ fontSize: mappedFontSize }}
    >
      {instructorStimulus !== "" && !editMode && (
        <div className="p-5 mb-4 bg-blue-100 rounded-md text-black">
          {instructorStimulus}
        </div>
      )}
      <FullWidthContainer>
        <LeftContent>
          <BlockTitle
            dangerouslySetInnerHTML={{
              __html: `Question: ${currentQuestion?.question}`,
            }}
          />
        </LeftContent>
        <RightContent>
          {editMode ? (
            <>
              <ButtonLink
                margin
                title={"Go to edit"}
                onClick={() => handleEditClick()}
              />
              <ButtonLink
                margin
                title={"Delete"}
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
              />
              {isModalOpen && (
                <QuestiontypeModal
                  // @ts-ignore
                  setIsModalOpen={setIsModalOpen}
                  id={currentQuestion?.id as any}
                  isModalOpen={isModalOpen}
                />
              )}
            </>
          ) : (
            <>
              {/* <Button
                variant="outlined"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  if (setShowAnswers) {
                    setShowAnswers(!showAnswer);
                  } else {
                    setLocalShowAnswer(!localShowAnswer);
                  }
                }}
              >
                {showAnswer || localShowAnswer ? "Hide Answer" : "Show Answer"}
              </Button> */}

              <button
                type="button"
                onClick={() => {
                  dispatch(setClosePreview());
                }}
                style={{
                  width: "150px",
                  height: "40px",
                  fontWeight: 600,
                  color: "#FFF",
                  borderRadius: "8px",
                  backgroundColor: "#FA9B31",
                }}
              >
                Back to edit
              </button>
            </>
          )}
        </RightContent>
      </FullWidthContainer>

      <div>
        {isMounted && (
          <div className={isOverLimit ? "editor-over-limit" : "editor"}>
            <CustomJoditEditor
                label=""
                value={content}
                onBlur={(newContent) => handleEditorChange(newContent)}
                allconfig={false}
                userconfig={joditConfig}
                spellCheck={currentQuestion?.more_options?.ui_style?.spellcheck}
                minHeight={currentQuestion?.more_options?.ui_style?.min_height}
                maxHeight={currentQuestion?.more_options?.ui_style?.max_height}
            />
            {isCharaModalOpen && (
              <SpecialCharacterModal
              specialCharacters={specialCharacters}
              onSelect={(char) => {
                  console.log("Selected character:", char); // Debugging
                  insertCharacter(char);
              }}
              onClose={() => setCharaModalOpen(false)}
          />
            )}
          </div>
        )}
        {/* <div
          className="flex gap-1 mt-2"
          style={isOverLimit ? { color: 'red' } : {}}
        >
          {currentQuestion?.options?.show_word_limit === "off" ? (
            ""
          ) : (
            <p>Word Limit: {maxWords}</p>
          )}
          {currentQuestion?.more_options?.ui_style?.show_word_count && (
            <p>
              {currentQuestion?.options?.show_word_limit === "off"
                ? "Word(s) "
                : "/ "}
              {wordCount}
            </p>
          )}
        </div> */}
        <div
          className="flex gap-1 mt-2"
          style={isOverLimit ? { color: "red" } : {}}
        >
          {currentQuestion?.options?.show_word_limit === "alwaysVisible" ||
          currentQuestion?.options?.show_word_limit === "" ? (
            <p>{maxWords} Word Limit</p>
          ) : null}

          {currentQuestion?.options?.show_word_limit === "onLimit" &&
            wordCount === maxWords && <p>{maxWords} Word Limit</p>}
        </div>
      </div>

      {isModalOpen && (
        <QuestiontypeModal
          setIsModalOpen={setIsModalOpen}
          id={currentQuestion?.id as any}
          isModalOpen={isModalOpen}
        />
      )}
    </QuestionContent>
  );
};

export default PreviewMathEssayWithText;
