import React from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "types";
import DraggableElement from "../DraggableElement/DraggableElement";

interface DroppableAreaProps {
  onDrop: (targetId: number, label: string, sourceId?: number, itemId?: string) => void;
  id: number;
  droppedItems: any;
  className?: string;
  height?: number;
}

const DroppableArea: React.FC<DroppableAreaProps> = ({ onDrop, id, droppedItems, className, height }) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.MATCH,
    drop: (item: { label: string, sourceId?: number, itemId?: string }) => {
      onDrop(id, item.label, item.sourceId, item.itemId);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const handleRemove = (itemId: string) => {
    onDrop(-1, itemId);
  };

  return (
    <div
      ref={drop}
      className={`p-2 border border-gray-300 rounded-md ${className}`}
      style={{
        minHeight: height || 50,
        minWidth: "100px",
        backgroundColor: isOver ? "lightgreen" : "white",
        margin: "4px",
      }}
    >
      {droppedItems?.map((item, index) => (
        <DraggableElement 
          key={index} 
          label={item.label} 
          onRemove={() => handleRemove(item.id)} 
          showRemove={(id == -1 || id == undefined) ? false :true} 
          sourceId={id} // Pass the sourceId to the DraggableElement
          itemId={item.id} // Pass the itemId to the DraggableElement
        />
      ))}
    </div>
  );
};

export default DroppableArea;