import uuid from "react-uuid";
import {
  buttonsSettings,
  drawParabola,
  drawPoint,
  drawSine,
  generateTwoPointsShape,
  getNewCoordsValueAfterSnapping,
  getSnappingValue,
} from "utils/graph/shapeDrawerUtil";

export const handleBoardDelete = (
  board,
  object,
  graphPoints,
  setCustomGraphOptions,
  setDeleteStart,
  setDeletedElements,
  isChild
) => {
  const selectedId = isChild
    ? graphPoints.current.find((item) => item.itemId === object.id).parentId
    : object.id;
  console.log(object, "object", selectedId);
  if (!object || !object.elType) {
    return;
  }
  object.off("up");
  object.on("up", () => {
    setCustomGraphOptions((prevData) => {
      const selectedObject = prevData.find(
        (item) => item.shapeId === selectedId
      );
      console.log(selectedObject, "selectedObject");
      if (object?.vertices?.length > 0) {
        object.vertices.forEach((vertex) => {
          board.removeObject(vertex);
        });
      }
      board.removeObject(object);
      const parent = prevData.find((item) => item.shapeId === object.id);
      console.log(parent, "parent");
      if (parent) {
        board.removeObject(parent);
      }
    });

    // setDeletedElements((prevData) => [...prevData, selectedObject.itemId]);
  });
  board.update();
  setDeleteStart(false);
};

export const handleBoardAddLabel = (
  board,
  elementClicked,
  object,
  graphPoints,
  event,
  setLabelState,
  isChild
) => {
  // console.log(elementClicked, "elementClicked", object);
  if (!object || !elementClicked) {
    return;
  }
  //   console.log(graphPoints.current,"clickedObjectDrag ", elementClicked);
  const selectedId = isChild
    ? graphPoints.current.find((item) => item.itemId === elementClicked.id)
        .parentId
    : elementClicked.id;
  const selectedObject = graphPoints.current.find(
    (item) => item.shapeId === selectedId
  );

  if (!selectedObject) {
    return;
  }
  object.off("up");
  elementClicked.off("up");
  if (elementClicked.point1 && elementClicked.point2) {
    // Get the coordinates of the points
    const point1Coords = {
      x: elementClicked.point1.X(),
      y: elementClicked.point1.Y(),
    };
    const point2Coords = {
      x: elementClicked.point2.X(),
      y: elementClicked.point2.Y(),
    };

    // Convert to viewport coordinates
    const topLeft = board.getMousePosition(event);
    const viewportCoords1 = {
      x: point1Coords.x + topLeft[0],
      y: point1Coords.y + topLeft[1],
    };
    const viewportCoords2 = {
      x: point2Coords.x + topLeft[0],
      y: point2Coords.y + topLeft[1],
    };

    const state = {
      element: elementClicked,
      x: Math.round((viewportCoords1.x + viewportCoords2.x) / 2),
      y: Math.round((viewportCoords1.y + viewportCoords2.y) / 2),
      selectedObject: selectedObject,
      isChild: false,
    };
    setLabelState(state);
  } else {
    const positions = board
      .getAllUnderMouse(event)
      .find((item) => Array.isArray(item));
    const state = {
      element: elementClicked,
      x: positions[0] + board.getMousePosition(event)[0],
      y: positions[1] + board.getMousePosition(event)[1],
      selectedObject: selectedObject,
      isChild: isChild,
    };
    setLabelState(state);
  }
};
export const handlePointClick = (
  board,
  x,
  y,
  graphSettings,
  activeTabIndex,
  setCustomGraphOptions,
  selectedButton
) => {
  const point = drawPoint(board, x, y, {
    snapping: graphSettings?.more_options,
  });
  console.log(point, "pointCreated");
  const snapper = getSnappingValue(graphSettings?.more_options);
  let gObj = {
    id: uuid(),
    type: selectedButton,
    hasParent: false,
    coords: {
      x: getNewCoordsValueAfterSnapping(
        x,
        snapper.snapToGrid,
        snapper.snapSizeX
      ),
      y: getNewCoordsValueAfterSnapping(
        y,
        snapper.snapToGrid,
        snapper.snapSizeY
      ),
    },
    tab: activeTabIndex,
    drawKey: point,
    options: {
      snapToGrid: snapper.snapToGrid,
      snapSizeX: snapper.snapSizeX,
      snapSizeY: snapper.snapSizeY,
    },
  };
  setCustomGraphOptions((prevData) => {
    const updatedData = [...prevData, gObj];
    return updatedData;
  });
  board.update();
};
let pointAData, pointBData;
export const handleTwoPointsShape = (
  board,
  newPoint,
  graphSettings,
  activeTabIndex,
  setCustomGraphOptions,
  pointsRef,
  elementsRef,
  selectedButton,
  questiontype
) => {
  console.log(selectedButton, "handleTwoPointsShape");

  if (!pointsRef.current.shapeDrawer) {
    pointsRef.current.shapeDrawer = generateTwoPointsShape(
      board,
      "draw",
      graphSettings?.more_options,
      true
    );
    pointsRef.current.shapeDrawer.next(); // Start generator
  }

  const snapper = getSnappingValue(graphSettings?.more_options);
  pointsRef.current.points.push(newPoint);
  elementsRef.current.push(newPoint);

  if (pointsRef.current.points.length === 1) {
    // First point (startCoords)
    const pointA = pointsRef.current.shapeDrawer.next(newPoint).value;
    pointAData = {
      id: uuid(),
      type: "Point",
      hasParent: true,
      coords: {
        x: getNewCoordsValueAfterSnapping(
          pointA.X(),
          snapper.snapToGrid,
          snapper.snapSizeX
        ),
        y: getNewCoordsValueAfterSnapping(
          pointA.Y(),
          snapper.snapToGrid,
          snapper.snapSizeY
        ),
      },
      tab: activeTabIndex,
      drawKey: pointA,
      options: {
        snapToGrid: snapper.snapToGrid,
        snapSizeX: snapper.snapSizeX,
        snapSizeY: snapper.snapSizeY,
      },
    };
    board.update();
  }

  if (pointsRef.current.points.length === 2) {
    // Second point (endCoords)
    pointsRef.current.shapeDrawer.next();
    const pointB = pointsRef.current.shapeDrawer.next(
      pointsRef.current.points[1]
    ).value;

    pointBData = {
      id: uuid(),
      type: "Point",
      hasParent: true,
      coords: {
        x: getNewCoordsValueAfterSnapping(
          pointB.X(),
          snapper.snapToGrid,
          snapper.snapSizeX
        ),
        y: getNewCoordsValueAfterSnapping(
          pointB.Y(),
          snapper.snapToGrid,
          snapper.snapSizeY
        ),
      },
      tab: activeTabIndex,
      drawKey: pointB,
      options: {
        snapToGrid: snapper.snapToGrid,
        snapSizeX: snapper.snapSizeX,
        snapSizeY: snapper.snapSizeY,
      },
    };
    board.update();

    // Generate the shape ID early
    const shapeId = uuid();

    // Pass the shape type and create the shape
    pointsRef.current.shapeDrawer.next();
    const shapeDetails = {
      type: buttonsSettings[selectedButton].shape,
      options: { ...buttonsSettings[selectedButton].options },
    };
    const shape = pointsRef.current.shapeDrawer.next(shapeDetails).value;

    if (!shape) {
      console.error("Shape was not created by the generator.");
      return;
    }

    board.update();

    pointAData.parentID = shapeId;
    pointBData.parentID = shapeId;

    // Extract coordinates for shape
    const { x: x1, y: y1 } = pointsRef.current.points[0];
    const { x: x2, y: y2 } = pointsRef.current.points[1];

    setCustomGraphOptions((prevData) => [
      ...prevData,
      pointAData,
      pointBData,
      {
        id: shapeId,
        type: selectedButton,
        tab: activeTabIndex,
        drawKey: shape,
        hasParent: false,
        subElementIds: {
          startPoint: {
            x: getNewCoordsValueAfterSnapping(
              x1,
              snapper.snapToGrid,
              snapper.snapSizeX
            ),
            y: getNewCoordsValueAfterSnapping(
              y1,
              snapper.snapToGrid,
              snapper.snapSizeY
            ),
          },
          endPoint: {
            x: getNewCoordsValueAfterSnapping(
              x2,
              snapper.snapToGrid,
              snapper.snapSizeX
            ),
            y: getNewCoordsValueAfterSnapping(
              y2,
              snapper.snapToGrid,
              snapper.snapSizeY
            ),
          },
        },
        options: {
          snapToGrid: snapper.snapToGrid,
          snapSizeX: snapper.snapSizeX,
          snapSizeY: snapper.snapSizeY,
        },
      },
    ]);

    // Reset for the next shape
    pointsRef.current.shapeDrawer = null;
    pointsRef.current.points = [];
    elementsRef.current = [];
    // pointBData = {};
    // pointAData = {};
  }
};

export const handlePolygonClick = (
  board,
  newPoint,
  graphSettings,
  activeTabIndex,
  setCustomGraphOptions,
  elementsRef,
  selectedButton,
  boardResetting,
  questiontype,
  polygonDone
) => {
  elementsRef.current.push(newPoint);
  const snapper = getSnappingValue(graphSettings?.more_options);
  const { polygonState, polygonShape, polygonPoints, segments } =
    buttonsSettings[selectedButton].draw(board, newPoint, {
      snapToGrid: snapper.snapToGrid,
      snapSizeX: snapper.snapSizeX,
      snapSizeY: snapper.snapSizeY,
      removePoints: boardResetting,
      userDoneDrawing: questiontype,
      isPolygonDone: polygonDone,
    });
  if (polygonState) {
    let gtObj = {
      id: uuid(),
      type: selectedButton,
      tab: activeTabIndex,
      drawKey: polygonShape,
      hasParent: false,
      subElementIds: {
        startPoint: {
          x: getNewCoordsValueAfterSnapping(
            newPoint.x,
            snapper.snapToGrid,
            snapper.snapSizeX
          ),
          y: getNewCoordsValueAfterSnapping(
            newPoint.y,
            snapper.snapToGrid,
            snapper.snapSizeY
          ),
        },
        endPoint: {
          x: getNewCoordsValueAfterSnapping(
            newPoint.x,
            snapper.snapToGrid,
            snapper.snapSizeX
          ),
          y: getNewCoordsValueAfterSnapping(
            newPoint.y,
            snapper.snapToGrid,
            snapper.snapSizeY
          ),
        },
        points: polygonPoints.map((point) => {
          return {
            x: getNewCoordsValueAfterSnapping(
              point.X(),
              snapper.snapToGrid,
              snapper.snapSizeX
            ),
            y: getNewCoordsValueAfterSnapping(
              point.Y(),
              snapper.snapToGrid,
              snapper.snapSizeY
            ),
          };
        }),
        segments,
        pointsShapes: polygonPoints,
      },
      options: {
        snapToGrid: snapper.snapToGrid,
        snapSizeX: snapper.snapSizeX,
        snapSizeY: snapper.snapSizeY,
      },
    };
    setCustomGraphOptions((prevData) => {
      console.log(gtObj, "gtObj");
      const updatedData = [...prevData, gtObj];
      return updatedData;
    });
    elementsRef.current = [];
    // polygonStateRef.current = polygonState;
  }
};

export const handleMovingObject = (
  board,
  x,
  y,
  object,
  elementClicked,
  setCustomGraphOptions,
  graphPoints,
  graphSettings,
  isChild
) => {
  console.log(elementClicked, graphPoints.current, "elss", isChild);
  const selectedId = isChild
    ? graphPoints.current.find((item) => item.itemId === elementClicked[0].id)
        .parentId
    : elementClicked[0].id;
  //   console.log(selectedId, 'selectedId');
  const snapper = getSnappingValue(graphSettings?.more_options);
  if (!object || !elementClicked.length) return;
  if (elementClicked[0].id === object.id) {
    elementClicked[0].off("up");
    elementClicked[0].on("up", (e) => {
      const coords = board.getUsrCoordsOfMouse(e);
      const dx = coords[0] - x;
      const dy = coords[1] - y;
      const selectedObject = graphPoints.current.find(
        (element) =>
          element.shapeId === selectedId || object.id === element.shapeId
      );
      const objInPolygon = Object.values(object.descendants).filter(
        // @ts-ignore
        (item) => item.elType === "polygon"
      );
      if (objInPolygon.length) {
        setCustomGraphOptions((prevData) => {
          const objInBoard = prevData.find(
            (element) => element.id === selectedObject.itemId
          );
          const updatedObject = {
            ...objInBoard,
            subElementIds: {
              ...objInBoard.subElementIds,
              points: objInBoard.subElementIds.points.map((point) => {
                if (point.x === x && point.y === y) {
                  return {
                    x: getNewCoordsValueAfterSnapping(
                      coords[0],
                      snapper.snapToGrid,
                      snapper.snapSizeX
                    ),
                    y: getNewCoordsValueAfterSnapping(
                      coords[1],
                      snapper.snapToGrid,
                      snapper.snapSizeY
                    ),
                    label: point.label,
                  };
                } else if (
                  point.x === object?.point1?.coords?.usrCoords[1] &&
                  point.y === object?.point2?.coords?.usrCoords[2]
                ) {
                  return {
                    x: getNewCoordsValueAfterSnapping(
                      coords[0],
                      snapper.snapToGrid,
                      snapper.snapSizeX
                    ),
                    y: getNewCoordsValueAfterSnapping(
                      coords[1],
                      snapper.snapToGrid,
                      snapper.snapSizeY
                    ),
                    label: point.label,
                  };
                } else {
                  return point;
                }
              }),
            },
          };
          const filteredData = prevData.filter(
            (element) => element.id !== selectedObject.itemId
          );
          return [...filteredData, updatedObject];
        });
        return;
      }
      object.setPosition(JXG.COORDS_BY_USER, [dx, dy]);
      elementClicked[0].off("up");
      if (!selectedObject) return;
      if (selectedObject.shapeId !== object.id) {
        setCustomGraphOptions((prevData) => {
          const objInBoard = prevData.find(
            (element) => element.id === selectedObject.itemId
          );
          if (!objInBoard) return prevData; // If no parent object, return unchanged

          const isStartPoint =
            objInBoard.subElementIds.startPoint.x + dx === coords[0];

          // Update the object's startPoint or endPoint
          const updatedObject = {
            ...objInBoard,
            subElementIds: {
              startPoint: isStartPoint
                ? {
                    x: getNewCoordsValueAfterSnapping(
                      coords[0],
                      snapper.snapToGrid,
                      snapper.snapSizeX
                    ),
                    y: getNewCoordsValueAfterSnapping(
                      coords[1],
                      snapper.snapToGrid,
                      snapper.snapSizeY
                    ),
                    label: objInBoard.subElementIds.startPoint.label,
                  }
                : objInBoard.subElementIds.startPoint,
              endPoint: isStartPoint
                ? objInBoard.subElementIds.endPoint
                : {
                    x: getNewCoordsValueAfterSnapping(
                      coords[0],
                      snapper.snapToGrid,
                      snapper.snapSizeX
                    ),
                    y: getNewCoordsValueAfterSnapping(
                      coords[1],
                      snapper.snapToGrid,
                      snapper.snapSizeY
                    ),
                    label: objInBoard.subElementIds.endPoint.label,
                  },
            },
            options: {
              ...objInBoard.options,
            },
          };

          // Filter out the old object and replace it with the updated one
          const filteredData = prevData.filter(
            (element) => element.id !== selectedObject.itemId
          );
          return [...filteredData, updatedObject];
        });
        return;
      }
      setCustomGraphOptions((prevData) => {
        const objInBoard = prevData.find(
          (element) => element.id === selectedObject.itemId
        );
        const newObject =
          elementClicked[0].elType === "point" && object.elType === "point"
            ? {
                ...objInBoard,
                coords: {
                  x: getNewCoordsValueAfterSnapping(
                    coords[0],
                    snapper.snapToGrid,
                    snapper.snapSizeX
                  ),
                  y: getNewCoordsValueAfterSnapping(
                    coords[1],
                    snapper.snapToGrid,
                    snapper.snapSizeY
                  ),
                },
                options: {
                  ...objInBoard.options,
                },
              }
            : ({
                ...objInBoard,
                subElementIds: {
                  startPoint: {
                    x: getNewCoordsValueAfterSnapping(
                      objInBoard.subElementIds.startPoint.x + dx,
                      snapper.snapToGrid,
                      snapper.snapSizeX
                    ),
                    y: getNewCoordsValueAfterSnapping(
                      objInBoard.subElementIds.startPoint.y + dy,
                      snapper.snapToGrid,
                      snapper.snapSizeY
                    ),
                    label: objInBoard.subElementIds.startPoint.label,
                  },
                  endPoint: {
                    x: getNewCoordsValueAfterSnapping(
                      objInBoard.subElementIds.endPoint.x + dx,
                      snapper.snapToGrid,
                      snapper.snapSizeX
                    ),
                    y: getNewCoordsValueAfterSnapping(
                      objInBoard.subElementIds.endPoint.y + dy,
                      snapper.snapToGrid,
                      snapper.snapSizeY
                    ),
                    label: objInBoard.subElementIds.endPoint.label,
                  },
                },
                options: {
                  ...objInBoard.options,
                },
              } as any);
        if (objInBoard?.subElementIds?.points) {
          newObject.subElementIds.points = objInBoard.subElementIds.points.map(
            (point) => {
              return {
                x: getNewCoordsValueAfterSnapping(
                  point.x + dx,
                  snapper.snapToGrid,
                  snapper.snapSizeX
                ),
                y: getNewCoordsValueAfterSnapping(
                  point.y + dy,
                  snapper.snapToGrid,
                  snapper.snapSizeY
                ),
                label: point.label,
              };
            }
          );
        }
        const filteredData = prevData.filter(
          (element) => element.id !== selectedObject.itemId
        );
        return [...filteredData, newObject];
      });
    });
  }
  if (elementClicked.length > 1 && elementClicked[0].id !== object.id) {
    elementClicked[0].off("up");
    object.on("up", (e) => {
      const coords = board.getUsrCoordsOfMouse(e);
      const dx = coords[0] - x;
      const dy = coords[1] - y;
      const selectedObject = graphPoints.current.find(
        (element) =>
          element.shapeId === selectedId ||
          element.shapeId === elementClicked[0]?.parentPolygon?.id
      );
      object.off("up");
      setCustomGraphOptions((prevData) => {
        const objInBoard = prevData.find(
          (element) => element.id === selectedObject.itemId
        );
        // console.log(objInBoard, "objInBoardPolygon");

        if (!objInBoard) return prevData; // Handle case where objInBoard is not found

        if (objInBoard.drawKey.elType === "polygon") {
          // console.log("polygonMove")
          elementClicked[0].on("up", (e) => {
            // console.log("polygonUp");
            const coords = board.getUsrCoordsOfMouse(e);
            const borderClicked = object.borders.find(
              (border) => border.id === elementClicked[0].id
            );
            // console.log(borderClicked, "borderClicked");
            const line1X = borderClicked.point1.X();
            const line1Y = borderClicked.point1.Y();
            const line2X = borderClicked.point2.X();
            const line2Y = borderClicked.point2.Y();
            const dx = coords[0] - x;
            const dy = coords[1] - y;
            borderClicked.point1.setPosition(JXG.COORDS_BY_USER, [dx, dy]);
            borderClicked.point2.setPosition(JXG.COORDS_BY_USER, [dx, dy]);
            const newObject = {
              ...objInBoard,
              subElementIds: {
                startPoint: {
                  x: getNewCoordsValueAfterSnapping(
                    objInBoard.subElementIds.startPoint.x,
                    snapper.snapToGrid,
                    snapper.snapSizeX
                  ),
                  y: getNewCoordsValueAfterSnapping(
                    objInBoard.subElementIds.startPoint.y,
                    snapper.snapToGrid,
                    snapper.snapSizeY
                  ),
                  label: objInBoard.subElementIds.startPoint.label,
                },
                endPoint: {
                  x: getNewCoordsValueAfterSnapping(
                    objInBoard.subElementIds.endPoint.x,
                    snapper.snapToGrid,
                    snapper.snapSizeX
                  ),
                  y: getNewCoordsValueAfterSnapping(
                    objInBoard.subElementIds.endPoint.y,
                    snapper.snapToGrid,
                    snapper.snapSizeY
                  ),
                  label: objInBoard.subElementIds.endPoint.label,
                },
                points: objInBoard.subElementIds.points.map((point) => {
                  if (
                    (Math.round(point.x + dx) === line1X &&
                      Math.round(point.y + dy) === line1Y) ||
                    (Math.round(point.x + dx) === line2X &&
                      Math.round(point.y + dy) === line2Y)
                  ) {
                    return {
                      x: getNewCoordsValueAfterSnapping(
                        Math.round(point.x + dx),
                        snapper.snapToGrid,
                        snapper.snapSizeX
                      ),
                      y: getNewCoordsValueAfterSnapping(
                        Math.round(point.y + dy),
                        snapper.snapToGrid,
                        snapper.snapSizeY
                      ),
                      label: point.label,
                    };
                  } else {
                    return point;
                  }
                }),
              },
              options: {
                ...objInBoard.options,
              },
            };
            elementClicked[0].off("up");
            // Update the state inside the event handler
            setCustomGraphOptions((prevData) => {
              const filteredData = prevData.filter(
                (element) => element.id !== selectedObject.itemId
              );
              return [...filteredData, newObject];
            });
          });
        } else {
          // Handle non-polygon case
          const isStartPoint =
            objInBoard.subElementIds.startPoint.x + dx === coords[0];
          board.removeObject(object);

          const newShape =
            objInBoard.type === "Sine"
              ? drawSine(board, [
                  [
                    getNewCoordsValueAfterSnapping(
                      objInBoard.subElementIds.startPoint.x + dx,
                      snapper.snapToGrid,
                      snapper.snapSizeX
                    ),
                    getNewCoordsValueAfterSnapping(
                      objInBoard.subElementIds.startPoint.y + dy,
                      snapper.snapToGrid,
                      snapper.snapSizeY
                    ),
                  ],
                  [
                    getNewCoordsValueAfterSnapping(
                      objInBoard.subElementIds.endPoint.x + dx,
                      snapper.snapToGrid,
                      snapper.snapSizeX
                    ),
                    getNewCoordsValueAfterSnapping(
                      objInBoard.subElementIds.endPoint.y + dy,
                      snapper.snapToGrid,
                      snapper.snapSizeY
                    ),
                  ],
                ])
              : drawParabola(board, [
                  [
                    getNewCoordsValueAfterSnapping(
                      objInBoard.subElementIds.startPoint.x + dx,
                      snapper.snapToGrid,
                      snapper.snapSizeX
                    ),
                    getNewCoordsValueAfterSnapping(
                      objInBoard.subElementIds.startPoint.y + dy,
                      snapper.snapToGrid,
                      snapper.snapSizeY
                    ),
                  ],
                  [
                    getNewCoordsValueAfterSnapping(
                      coords[0],
                      snapper.snapToGrid,
                      snapper.snapSizeX
                    ),
                    getNewCoordsValueAfterSnapping(
                      coords[1],
                      snapper.snapToGrid,
                      snapper.snapSizeY
                    ),
                  ],
                ]);

          const newObject = {
            ...objInBoard,
            drawKey: newShape,
            subElementIds: {
              startPoint: {
                x: getNewCoordsValueAfterSnapping(
                  isStartPoint
                    ? coords[0]
                    : objInBoard.subElementIds.startPoint.x,
                  snapper.snapToGrid,
                  snapper.snapSizeX
                ),
                y: getNewCoordsValueAfterSnapping(
                  isStartPoint
                    ? coords[1]
                    : objInBoard.subElementIds.startPoint.y,
                  snapper.snapToGrid,
                  snapper.snapSizeY
                ),
                label: objInBoard.subElementIds.startPoint.label,
              },
              endPoint: {
                x: getNewCoordsValueAfterSnapping(
                  !isStartPoint
                    ? coords[0]
                    : objInBoard.subElementIds.endPoint.x,
                  snapper.snapToGrid,
                  snapper.snapSizeX
                ),
                y: getNewCoordsValueAfterSnapping(
                  !isStartPoint
                    ? coords[1]
                    : objInBoard.subElementIds.endPoint.y,
                  snapper.snapToGrid,
                  snapper.snapSizeY
                ),
                label: objInBoard.subElementIds.endPoint.label,
              },
            },
            options: {
              ...objInBoard.options,
            },
          };

          // Update the state for non-polygon case
          const filteredData = prevData.filter(
            (element) => element.id !== selectedObject.itemId
          );
          return [...filteredData, newObject];
        }

        // Return the previous data if no changes are made yet
        return prevData;
      });
    });
    return;
  }
  board.update();
};
export const getNewLabelObjectState = (type, isChild, obj, elemnt, label) => {
  const newObject = {
    id: obj.id,
    drawKey: obj.drawKey,
    type: obj.type,
    tab: obj.tab,
    subElementIds: { ...getSubElements(type, isChild, obj, elemnt, label) },
    coords: { ...(obj.coords || {}) },
    options: {
      name: isChild ? obj.options.name : label,
      label: {
        fontSize: 14,
        fontWeight: "bold",
        fontFamily: "Arial",
        visible: true,
        textAlign: "center",
        textBaseline: "middle",
        opacity: 0.8,
        anchorX: "middle",
        anchorY: "middle",
        position: "top",
        offset: [20, 20],
      },
    },
  } as any;
  if (type === "Polygon") {
    newObject.options = {
      ...newObject.options,
      name: isChild ? obj.options.name : label,
    };
  }
  return newObject;
};

export const getSubElements = (type, isChild, obj, element, label) => {
  if (type === "Polygon") {
    const result = {
      ...obj.subElementIds,
      points: isChild
        ? obj.subElementIds.points.map((point) => {
            if (point.x === element.X() && point.y === element.Y()) {
              return {
                ...point,
                label: label,
              };
            } else {
              return {
                ...point,
              };
            }
          })
        : obj.subElementIds.points,
    };
    return result;
  }
  if (type === "Point") {
    return {};
  }
  if (type === "Circle") {
    // console.log("circleEntered", isChild, obj, element, label);
    const result = isChild
      ? {
          startPoint: {
            ...obj.subElementIds.startPoint,
            label:
              obj.drawKey.midpoint.coords.usrCoords[1] ===
                element.coords.usrCoords[1] &&
              obj.drawKey.midpoint.coords.usrCoords[2] ===
                element.coords.usrCoords[2]
                ? label
                : obj.subElementIds.startPoint.label,
          },
          endPoint: {
            ...obj.subElementIds.endPoint,
            label:
              obj.drawKey.point2.coords.usrCoords[2] ===
                element.coords.usrCoords[2] &&
              obj.drawKey.point2.coords.usrCoords[1] ===
                element.coords.usrCoords[1]
                ? label
                : obj.subElementIds.endPoint.label,
          },
        }
      : {
          startPoint: {
            ...obj.subElementIds.startPoint,
            label: obj.subElementIds.startPoint.label,
          },
          endPoint: {
            ...obj.subElementIds.endPoint,
            label: obj.subElementIds.endPoint.label,
          },
        };
    return result;
  }
  if (type === "Parabola" || type === "Sine") {
    const result = isChild
      ? {
          startPoint: {
            ...obj.subElementIds.startPoint,
            label:
              obj.subElementIds.startPoint.x ===
                Math.round(element.coords.usrCoords[1]) &&
              obj.subElementIds.startPoint.y ===
                Math.round(element.coords.usrCoords[2])
                ? label
                : obj.subElementIds.startPoint.label,
          },
          endPoint: {
            ...obj.subElementIds.endPoint,
            label:
              obj.subElementIds.endPoint.x ===
                Math.round(element.coords.usrCoords[1]) &&
              obj.subElementIds.endPoint.y ===
                Math.round(element.coords.usrCoords[2])
                ? label
                : obj.subElementIds.endPoint.label,
          },
        }
      : {
          startPoint: {
            ...obj.subElementIds.startPoint,
            label: obj.subElementIds.startPoint.label,
          },
          endPoint: {
            ...obj.subElementIds.endPoint,
            label: obj.subElementIds.endPoint.label,
          },
        };
    return result;
  }
  const result = isChild
    ? {
        startPoint: {
          ...obj.subElementIds.startPoint,
          label:
            obj.drawKey.point1.coords.usrCoords[1] ===
              element.coords.usrCoords[1] &&
            obj.drawKey.point1.coords.usrCoords[2] ===
              element.coords.usrCoords[2]
              ? label
              : obj.subElementIds.startPoint.label,
        },
        endPoint: {
          ...obj.subElementIds.endPoint,
          label:
            obj.drawKey.point2.coords.usrCoords[2] ===
              element.coords.usrCoords[2] &&
            obj.drawKey.point2.coords.usrCoords[1] ===
              element.coords.usrCoords[1]
              ? label
              : obj.subElementIds.endPoint.label,
        },
      }
    : {
        startPoint: {
          ...obj.subElementIds.startPoint,
          label: obj.subElementIds.startPoint.label,
        },
        endPoint: {
          ...obj.subElementIds.endPoint,
          label: obj.subElementIds.endPoint.label,
        },
      };
  return result;
};

export const getObjectClicked = (board, event, points) => {
  const objectsUnderMouse = board.getAllObjectsUnderMouse(event);
  // console.log(objectsUnderMouse, "objectsUnderMouse");
  const clickedParentObject = points.current
    .map((item) =>
      objectsUnderMouse.find(
        (obj) => obj.id === item.shapeId && !item.hasParent
      )
    )
    .filter(Boolean);
  // console.log(clickedParentObject, "clickedParentObject");

  const clickedChildObject = points.current
    .map((item) =>
      objectsUnderMouse.find((obj) => obj.id === item.shapeId && item.hasParent)
    )
    .filter(Boolean);
  // console.log(clickedChildObject, "clickedChildObject");
  const clickedObject =
    clickedChildObject?.length > 0
      ? clickedChildObject[0]
      : clickedParentObject[0];

  const isChild = clickedChildObject?.length > 0;

  return { clickedObject, objectsUnderMouse, isChild };
};
