import { ResponsiveBar } from '@nivo/bar'
import { useCallback, useRef, useState } from 'react';
import { ChartQuestionTypes } from './chartquestion.constants';
import {  getResponseFontSize } from 'utils/generalUtils';
import { FaArrowCircleLeft, FaArrowCircleRight, FaEdit, FaTrash } from 'react-icons/fa';
import ModalEditBarValue from './modalEditBarValue.component';
import { scaleLinear } from 'd3-scale';
import { AiFillCloseCircle } from 'react-icons/ai';

interface IPoint {id: string, x: string, y: string | number}

interface ChartGraphProps {
    activeTab: {value: any[], score: number | string},
    questionData: any,
    handleBarData: (newBarData: any[]) => void,
}

export default function ChartGraph({activeTab, questionData, handleBarData} : ChartGraphProps) {
    const {template_response} = questionData;
    const more_options = questionData?.more_options;
    const layout = more_options && more_options?.layout ? more_options?.layout : null;

    // console.log('[Chartgrap.tsx] more layout = ', layout)
    // console.log('[Chartgrap.tsx] active tab = ', activeTab)
    
    const fontSize = getResponseFontSize(more_options, true);
    const barData = activeTab?.value.map(point => ({...point, y: Number(point.y)}));
    const maxYValue = !isNaN(Number(template_response.max_y_value)) ? Math.round(Number(template_response.max_y_value)) : 100;
    const isHistogram = questionData?.type === ChartQuestionTypes.HISTOGRAM;
    const isMultiColor = (layout && Object.hasOwn(layout, 'multicolor_bars') && isHistogram) ? layout?.multicolor_bars : isHistogram;

    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [dragStartY, setDragStartY] = useState<number | null>(null);
    const [hoveredRectId, setHoveredRectId] = useState<string | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editedBar, setEditedBar] = useState<string | null>(null);
    const [clickedBar, setClickedBar] = useState<string | null>(null); 


    const handleEdit = (barValue: string) => {
        setEditedBar(barValue);
        setInputValue(barValue);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setInputValue('');
    };
    
    const handleDelete = (barValue: string) => {
        const newBarData = activeTab.value.filter((point: IPoint) => point.x != barValue);
        handleBarData(newBarData);
    };

    const handleOkForEditBar = () => {
        const newBarData = activeTab.value.map((point: IPoint) => {
            if(point.x === editedBar && inputValue){
                // return {...point, x: inputValue}
                return { ...point, x: inputValue, isEdited: true };

            }else {
                return point
            }
        });
        handleBarData(newBarData);
        handleModalClose();
    }


    const initialBarValues = useRef({...barData})

    const handleDragStart = useCallback((event, index) => {
        setDraggedIndex(index);
        setDragStartY(event.clientY);
        initialBarValues.current = [...barData];
    }, [barData]);

    const handleDragMove = useCallback((event) => {
        if (draggedIndex !== null && dragStartY !== null) {
            // const deltaY = dragStartY - event.clientY;
            const  deltaY = Math.round((dragStartY - event.clientY) * (0.4 * maxYValue / 100));
            const newBarData = activeTab.value.map((point, i) => {
                if (i === draggedIndex) {
                    // const calculatedValue = Math.min(maxYValue, Math.max(0, Number(point.y) + deltaY * 0.1));
                    const calculatedValue = Math.min(maxYValue, Math.max(0, Number(initialBarValues.current[i].y) + deltaY))
                    return {
                        ...point,
                        y: maxYValue >= 10 ? Math.round(calculatedValue) : calculatedValue, 
                    };
                }
                return point;
            })
            handleBarData(newBarData);
        }
    }, [draggedIndex, dragStartY]);

    const handleDragEnd = useCallback(() => {
        setDraggedIndex(null);
        setDragStartY(null);
    }, []);

    const handleReorderBars = (currentIndex: number, type: "left" | "right") => {
        const allPoints = activeTab?.value;
        let reorderedPoints = allPoints.filter((p: IPoint, index: number) => index != currentIndex );
        const currentPoint = allPoints.find((p: IPoint, idx) => idx == currentIndex);
        if(type == "right" && currentPoint) {
            reorderedPoints.splice(currentIndex + 1, 0, currentPoint);
        }else if(type == "left" && currentPoint) {
            reorderedPoints.splice(currentIndex - 1, 0, currentPoint);
        }
        handleBarData(reorderedPoints);
    }

  return (
    <div
        style={{
            border: '1px solid #D9D9D9',
            backgroundColor: '#f3f5f7',
            padding: '8px',
            marginBottom: '16px',
            // width: isHistogram ? '70%' : '100%', 
            width: '100%', 
            height: ['xx-large', 'x-large'].includes(fontSize) ? '500px' : '400px',
            userSelect: 'none',
            fontSize: fontSize,
        }}
        onMouseMove={handleDragMove}
        onMouseUp={handleDragEnd}
    >
        <ResponsiveBar
            theme={{
                text: {fontSize},
                // legends: {title: {text: {fontSize}}, ticks: {text: {fontSize}}, text: {fontSize}},
                axis: {
                    // ticks: {text: {fontSize}}, 
                    legend: {text: {fontSize, height: 50},}
                }
                // labels: {text: {fontSize}}
            }}
            data={barData}
            keys={["y"]}
            indexBy="x"
            margin={{top: 60, right: 130, bottom: 70, left: 90}}
            padding={isHistogram ? 0 : 0.4}
            valueScale={{type: "linear"}}
            colors="#3182CE"
            animate={true}
            enableLabel={true}
            label={(d) => `${d.value}`} 
            labelSkipWidth={20}
            labelSkipHeight={20}
            labelTextColor="white"
            axisRight={null}
            minValue={0}
            maxValue = {maxYValue}
            enableGridX={layout && layout?.show_gridlines ? ["both", "x_only"].includes(layout.show_gridlines) : true}
            enableGridY={layout && layout?.show_gridlines ? ["both", "y_only"].includes(layout.show_gridlines) : true}
            isInteractive={true}
            onClick={(e) => {}}
            axisTop= {{
                tickSize: 20,
                tickPadding: 5,
                tickRotation: 0,
                legend: template_response?.chartTitle,
                legendPosition: "middle",
                legendOffset: -40,
                renderTick: (props) => props.value,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: template_response?.yLabel || '',
                legendPosition: 'middle',
                legendOffset: -64,
                tickValues: (() => {
                    const ticks = scaleLinear()
                        .domain([0, maxYValue])
                        .ticks(10);
                    // Ensure the maxYValue is included and filter overlapping ticks
                    return ticks.concat(maxYValue).filter((value, index, self) => {
                        if (index === self.length - 1) return true; // Always include the max value
                        const nextValue = self[index + 1];
                        return nextValue === undefined || nextValue - value > maxYValue * 0.05; // Minimum 10% gap
                    });
                })(),
            }}
            axisBottom={{
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legend: template_response?.xLabel || '',
                legendPosition: 'middle',
                legendOffset:58,
                truncateTickAt: 0,
                renderTick: (props) => {
                    const currentPoint = activeTab.value.find((point: IPoint) => point.x === props.value);
            
                    const shouldShowIcons =
                (currentPoint?.isFromCorrectAnswer && !currentPoint?.isEdited) || 
                (layout?.edit_point || layout?.delete_point); 
            
                    return (
                        <g
                            onClick={() => {
                                if (shouldShowIcons) {
                                    setClickedBar(props.value);
                                }
                            }}
                        >
                            <text
                                x={props.x}
                                y={props.y + 20}
                                textAnchor="middle"
                                dominantBaseline="middle"
                            >
                                {props.value}
                            </text>
                            {clickedBar === props.value && shouldShowIcons && (
                                <g transform={`translate(${props.x - 40}, ${props.y + 30})`}>
                                    <rect
                                        width={(layout?.delete_point && layout?.edit_point) ? "60" : "50"}
                                        height="30"
                                        fill="white"
                                        stroke="gray"
                                        strokeWidth="0.5"
                                        rx="7"
                                        ry="5"
                                    />
                                    {(layout?.edit_point || currentPoint?.isFromCorrectAnswer ) && (
                                        <FaEdit
                                            color='gray'
                                            className={` cursor-pointer absolute hover:opacity-70 ${!!layout?.delete_point ? 'ml-5' : ""}`}
                                            x={7}
                                            y={5}
                                            size={22}
                                            onClick={(e) => {
                                                e.stopPropagation(); 
                                                handleEdit(props.value);
                                            }}
                                        />
                                    )}
                                    {(layout?.delete_point || currentPoint?.isFromCorrectAnswer) && (
                                        <FaTrash
                                            color="red"
                                            className={`cursor-pointer absolute ml-5 hover:opacity-70 ${!!layout?.edit_point ? 'ml-5' : ""}`}
                                            x={30}
                                            y={5}
                                            size={22}
                                            onClick={(e) => {
                                                e.stopPropagation(); 
                                                handleDelete(props.value);
                                            }}
                                        />
                                    )}
                                    {/* Close icon */}
                                    <g
                                        transform="translate(-10, -10)"
                                        onClick={(e) => {
                                            e.stopPropagation(); 
                                            setClickedBar(null); 
                                        }}
                                    >
                                        <AiFillCloseCircle
                                            size={22}
                                            color="red"
                                            className='cursor-pointer hover:opacity-70'
                                        />
                                    </g>
                                </g>
                            )}
                        </g>
                    );
                }
            }}
            
            layers={[
                'grid',
                'axes',
                'bars',
                ({ bars }) =>
                    bars.map((bar, index) => {
                        const minBarHeight = 10;
                        const barHeight = bar.height > 0 ? bar.height : minBarHeight;
                        const barId = bar?.data?.data?.id;

                        return (
                            <g 
                                key={bar.key} 
                                transform={`translate(${bar.x}, ${bar.y - (bar.height === 0 ? minBarHeight : 0)})`}
                                className='hover:opacity-60'
                                onMouseEnter={() => {
                                    if(layout?.order_point){
                                        setHoveredRectId(barId);
                                    }
                                }}
                                onMouseLeave={() => {
                                    if(hoveredRectId) setHoveredRectId(null);
                                }}
                            >
                                <rect
                                    width={bar.width}
                                    height={barHeight}
                                    fill={ isMultiColor && bar?.data?.data?.color ? bar?.data?.data?.color : bar.color}
                                    onMouseDown={(event) => handleDragStart(event, index)}
                                    style={{ cursor: 'grab' }}
                                />
                                <text
                                    x={bar.width / 2}
                                    y={barHeight > minBarHeight ? barHeight / 2 : -10}
                                    textAnchor="middle"
                                    dominantBaseline="central"
                                    fill="white"
                                    style={{ pointerEvents: 'none' }}
                                >
                                    {bar?.data?.value}
                                </text>

                                {hoveredRectId === barId && !!layout?.order_point && (
                                    <>
                                        {
                                            index != 0 && (
                                                <g 
                                                    transform={`translate(${isHistogram ? 0 :-16}, ${barHeight > minBarHeight ? barHeight / 2 : 0})`}
                                                >
                                                    <FaArrowCircleLeft 
                                                        color = "black"
                                                        size={24}
                                                        style={{cursor: 'pointer'}}
                                                        onClick={() => {handleReorderBars(index, "left")}}
                                                    />
                                                </g>
                                            )
                                        }
                                        {
                                            index != bars.length - 1 && (
                                                <g 
                                                    transform={`translate(${bar.width - (isHistogram ? 24 :8)}, ${barHeight > minBarHeight ? barHeight / 2 : 0})`}
                                                >
                                                    <FaArrowCircleRight 
                                                        color = "black"
                                                        size={24}
                                                        style={{cursor: 'pointer'}}
                                                        onClick={() => {handleReorderBars(index, "right")}}
                                                    />
                                                </g>
                                            )
                                        }
                                    </>
                                )}
                            </g>
                        );
                    }),
            ]}
        />

        <ModalEditBarValue
            showModal={showModal}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleCancel={handleModalClose}
            handleSave={handleOkForEditBar}
        />
          
    </div>
  )
}
