export interface SymbolLayout {
  label: string;
  tooltip: string;
  rows: string[][];
}

export const symbolConfigLayouts: Record<string, SymbolLayout> = {
  basic: {
    label: "Basic",
    tooltip: "Basic mathematical symbols",
    rows: [
      ["x", "y", "x^2", "\\sqrt{a}"],
      ["\\frac{x}", "x\\frac{x}{x}", "x^x", "y_{1}"],
      ["<", ">", "\\pm", "\\$", "\\%", "\\degree"],
      [":", "\\left(\\right)", "| x |", "\\pi", "\\infty"],
    ],
  },
  qwerty: {
    label: "QWERTY",
    tooltip: "QWERTY keyboard symbols",
    rows: [
      ["q", "w", "e", "r", "t", "y"],
      ["a", "s", "d", "f", "g", "h"],
    ],
  },
  basic_junior: {
    label: "Basic Junior",
    tooltip: "Simple math symbols for junior level",
    rows: [
      ["<", ">"],
      ["\\frac{x}", "x\\frac{x}{x}"],
    ],
  },
  algebra: {
    label: "Algebra",
    tooltip: "Algebraic symbols",
    rows: [
      ["x", "y", "x^2", "\\sqrt{a}"],
      ["\\frac{x}", "x\\frac{x}{x}", "x^x", "y_{1}"],
      ["<", ">", "\\pm", "\\$", "\\%", "\\degree"],
      [":", "\\left(\\right)", "| x |", "\\pi", "\\infty"],
    ],
  },
  comparison: {
    label: "Comparison",
    tooltip: "Comparison operators",
    rows: [
      ["≠", "≈"],
      ["<", ">"],
      ["≤", "≥"],
      ["≯", "≮"],
    ],
  },
  geometry: {
    label: "Geometry",
    tooltip: "Geometric symbols",
    rows: [
      ["\\perp", "\\parallel", "\\nparallel", "{\\sim}{\\mathbf{ }}"],
      ["\\angle", "\\measuredangle", "\\sim", "\\cong"],
      ["\\triangle", "⊙", "\\parallelogram", "\\overarc{ }"],
      ["\\degree", "'", '"', "\\pi"],
      [
        "\\frac{}",
        "\\overrightarrow{ }",
        "\\overleftrightarrow{ }",
        "\\square",
      ],
    ],
  },
  matrices: {
    label: "Matrices",
    tooltip: "Matrix symbols",
    rows: [
      ["\\begin{bmatrix}\\end{bmatrix}", "\\ldots", "⋮", "⋱"],
      ["_{x}", "x^x", "x1"],
    ],
  },
  trigonometry: {
    label: "Trigonometry",
    tooltip: "Trigonometric functions",
    rows: [
      ["\\sin", "\\sec", "\\sin^{-1}", "\\sec^{-1}"],
      ["\\cos", "\\csc", "\\cos^{-1}", "\\csc^{-1}"],
      ["\\tan", "\\cot", "\\tan^{-1}", "\\cot^{-1}"],
    ],
  },
  sets: {
    label: "Sets",
    tooltip: "Set theory symbols",
    rows: [
      ["\\subset", "\\supset", "\\subseteq", "\\supseteq", "\\cap"],
      ["⊄", "\\in", "\\notin", "\\ni", "\\cup"],
      [",", ":", "!", "\\backslash", "/"],
      [
        "\\varnothing",
        "\\left(\\right)",
        "\\left[\\right]",
        "\\{}\\}}",
        "\\left\\langle{}\\right\\rangle",
      ],
    ],
  },
  units_si: {
    label: "SI Units",
    tooltip: "International System of Units",
    rows: [
      [
        "\\text{g}",
        "\\text{kg}",
        "\\text{mg}",
        "\\text{µg}",
        "\\text{L}",
        "\\text{mL}",
      ],
      [
        "\\text{m}",
        "\\text{km}",
        "\\text{cm}",
        "\\text{mm}",
        "\\text{s}",
        "\\text{ms}",
      ],
    ],
  },
  units_us: {
    label: "US Units",
    tooltip: "United States customary units",
    rows: [
      ["in", "ft", "\\text{fl oz}", "mi"],
      ["oz", "lb", "gal", "pt"],
    ],
  },
  greek: {
    label: "Greek",
    tooltip: "Greek letters",
    rows: [
      ["\\alpha", "\\gamma", "\\delta", "\\pi"],
      ["\\theta", "\\sigma", "\\Delta", "\\Pi"],
      ["\\Theta", "\\Sigma", "\\lambda", "\\phi"],
      ["\\tau", "\\varepsilon", "\\beta", "\\mu"],
      ["\\rho"],
    ],
  },
  chemistry: {
    label: "Chemistry",
    tooltip: "Chemical symbols",
    rows: [
      ["x", "y", "x^2", "\\sqrt{a}"],
      ["\\frac{x}", "x\\frac{x}{x}", "x^x", "\\sqrt[]{}"],
      ["<", ">", "\\pm", "|{}|"],
      ["\\pi", "\\infty", "\\left(\\right)", "\\left[\\right]"],
    ],
  },
  grouping: {
    label: "Grouping",
    tooltip: "Grouping symbols",
    rows: [
      [
        "\\left(\\right)",
        "\\left[\\right]",
        "\\{}\\}}",
        "\\left\\langle{}\\right\\rangle",
      ],
    ],
  },
  calculus: {
    label: "Calculus",
    tooltip: "Calculus symbols",
    rows: [
      ["d", "f", "∆", "\\frac{x}"],
      ["( )", "∫", "∫∫", "∫∫∫"],
      ["\\sum_{}^{}", "\\partial", "\\lim _{x\to }", "∇"],
      ["i", "j", "k", "\\left\\langle{}\\right\\rangle"],
    ],
  },
  misc: {
    label: "Miscellaneous",
    tooltip: "Various symbols",
    rows: [
      ["a", "b", "\\infty", "abc"],
      ["|", "⋅", "⟌", "\\R"],
    ],
  },
  discrete: {
    label: "Discrete",
    tooltip: "Discrete math symbols",
    rows: [
      ["\\lfloor", "\\rfloor","\\lceil","\\rceil"],
      ["\\uparrow", "\\equiv","\\wedge","\\vee"],
      ["\\neg", "\\exists","\\forall","\\oplus"],
    ],
  },
  general: {
    label: "General",
    tooltip: "General mathematical symbols",
    rows: [
      ["x", "y", "x^2", "\\sqrt{a}"],
      ["\\frac{x}", "x\\frac{x}{x}", "x^x", "\\pm"],
      ["\\left(\\right)", "| x |", "\\pi", "\\infty"],
      ["∴", ":", "<", ">"],
    ],
  },
};
