import { ResponsiveBar } from '@nivo/bar';
import { useCallback, useRef, useState } from 'react';
import { FaArrowCircleLeft, FaArrowCircleRight, FaEdit, FaTrash } from 'react-icons/fa';
import { getResponseFontSize } from 'utils/generalUtils';
import ModalEditBarValue from './modalEditBarValue.component';
import { AiFillCloseCircle } from 'react-icons/ai';

interface IPoint {
    id: string;
    x: string;
    y: string | number;
    isFromCorrectAnswer?: boolean;
    isEdited?: boolean; // Add this new property
}
interface PlotGraphProps {
    activeTab: { value: any[], score: number | string },
    questionData: any,
    handleBarData: (newBarData: any[]) => void,
    disabled?: boolean,
}

export default function PlotGraph({ activeTab, questionData, handleBarData, disabled }: PlotGraphProps) {
    const template_response  = questionData?.template_response ? questionData?.template_response : {};
    const more_options = questionData?.more_options;
    const layout = more_options && more_options?.layout ? more_options?.layout : null;
    const fontSize = getResponseFontSize(more_options, true);
    
    const barData = activeTab?.value.map(point => ({ ...point, y: Number(point.y) }));
    const maxYValue = !isNaN(Number(template_response.max_y_value)) ? Number(template_response.max_y_value) : 10;
    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [dragStartY, setDragStartY] = useState<number | null>(null);
    const initialBarValues = useRef([...barData]);
    const bulletRadius = 10 * (10 / maxYValue);
    const spacing = 5;
    const scaleXY = maxYValue <= 30 ? 1 * 10 / maxYValue : 2 * 10 / maxYValue;

    const [hoveredBar, setHoveredBar] = useState<string | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editedBar, setEditedBar] = useState<string | null>(null);
    const [hoveredPointId, setHoveredPointId] = useState<string | null>(null);

    const handleEdit = (barValue: string) => {
        setEditedBar(barValue);
        setInputValue(barValue);
        
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setInputValue('');
    };
    
    const handleDelete = (barValue: string) => {
        const newBarData = activeTab.value.filter((point: IPoint) => point.x != barValue);
        handleBarData(newBarData);
    };

    const handleOkForEditBar = () => {
        const newBarData = activeTab.value.map((point: IPoint) => {
            if(point.x === editedBar && inputValue){
                return { ...point, x: inputValue, isEdited: true }; 
             }else {
                return point
            }
        });
        handleBarData(newBarData);
        handleModalClose();
    }


    const [linePosition, setLinePosition] = useState({ x1: 0, y1: 260, x2: 300, y2: 260 });

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if(disabled) return;
        const rect = event.currentTarget.getBoundingClientRect();
        const y = event.clientY - rect.top - (bulletRadius * 2 + spacing * 2);
        setLinePosition({ 
            x1: 0,
            y1: y, 
            y2: y,
            x2: rect.right
        });
    };

    const handleDragStart = useCallback((event, index) => {
        setDraggedIndex(index);
        setDragStartY(event.clientY);
        initialBarValues.current = [...barData];
    }, [barData]);

    const handleDragMove = useCallback((clientY) => {
        if (draggedIndex !== null && dragStartY !== null) {
            const  deltaY = Math.floor(((dragStartY - clientY) * (0.35 * maxYValue/bulletRadius ))/maxYValue);
            // console.log('deltaY = ',deltaY);
            const newBarData = activeTab.value.map((point, i) => {
                if (i === draggedIndex) {
                    const calculatedValue = Math.min(
                        maxYValue,
                        Math.max(0, initialBarValues.current[i].y + deltaY)
                    );
                    return {
                        ...point,
                        y: calculatedValue,
                    };
                }
                return point;
            });
            handleBarData(newBarData);
        }
        },
        [draggedIndex, dragStartY, maxYValue, activeTab.value, handleBarData]
    );

    const handleMouseMoveWithDrag = (event: React.MouseEvent<HTMLDivElement>) => {
        handleDragMove(event.clientY);
        handleMouseMove(event);
    }
    const handleDragEnd = useCallback(() => {
        setDraggedIndex(null);
        setDragStartY(null);
    }, []);
    const [clickedBar, setClickedBar] = useState<string | null>(null); // New state to track clicked bar

    const handleReorderPoints = (currentIndex: number, type: "left" | "right") => {
        const allPoints = activeTab?.value;
        let reorderedPoints = allPoints.filter((p: IPoint, index: number) => index != currentIndex );
        const currentPoint = allPoints.find((p: IPoint, idx) => idx == currentIndex);
        if(type == "right" && currentPoint) {
            reorderedPoints.splice(currentIndex + 1, 0, currentPoint);
        }else if(type == "left" && currentPoint) {
            reorderedPoints.splice(currentIndex - 1, 0, currentPoint);
        }
        handleBarData(reorderedPoints);
    }

    return questionData ? (
        <div
            style={{
                border: '1px solid #D9D9D9',
                backgroundColor: '#f3f5f7',
                padding: '8px',
                marginBottom: '16px',
                width: '100%',
                height: '600px',
                userSelect: 'none',
                fontSize,
            }}
            onMouseMove={(e) => {
                // handleDragMove(e)
                handleMouseMoveWithDrag(e);
                handleMouseMove(e)
            }}
            onMouseUp={handleDragEnd}
        >
            <ResponsiveBar
                theme={{
                    text: {fontSize},
                    axis: {legend: {text: {fontSize, height: 50},}}
                }}
                data={barData}
                keys={["y"]}
                indexBy="x"
                // margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                margin={{top: 60, right: 130, bottom: 70, left: 90}}
                valueScale={{ type: "linear" }}
                minValue={0}
                maxValue={maxYValue}
                colors="#3182CE"
                animate={true}
                isInteractive={true}
                axisTop= {{
                    tickSize: 20,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: template_response?.chartTitle || "",
                    legendPosition: "middle",
                    legendOffset: -40,
                    renderTick: (props) => <h2 className='text-blue-500'>{props.value}</h2>
                }}
                axisBottom={{
                    tickSize: 10,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: template_response?.xLabel || '',
                    legendPosition: 'middle',
                    legendOffset: 58,
                    // renderTick: (props) => (
                    //     <g
                    //       onMouseEnter={() => {
                    //         const currentPoint = activeTab.value.find((point: IPoint) => point.x == props.value);
                    //         if(currentPoint){
                    //             setHoveredBar(props?.value)
                    //         }
                    //     }}
                    //       onMouseLeave={() => {
                    //         if(hoveredBar)setHoveredBar(null)
                    //     }}
                    //     >
                    //       <text
                    //         x={props.x}
                    //         y={props.y + 20}
                    //         textAnchor="middle"
                    //         dominantBaseline="middle"
                    //       >
                    //         {props.value}
                    //       </text>
                    //       {hoveredBar === props.value && (
                    //         <>
                    //          {
                    //             !!layout?.edit_point &&
                    //             <g transform={`translate(${props.x - 5}, ${20})`}>
                    //                 <FaEdit
                    //                     color='gray'
                    //                     style={{ cursor: 'pointer', marginRight: '8px',  }}
                    //                     onClick={() => handleEdit(props.value)}
                    //                 />
                    //             </g>
                    //          }
                            
                    //          {
                    //             !!layout?.delete_point &&
                    //             <g transform={`translate(${props.x - 25}, ${20})`}>
                    //                 <FaTrash
                    //                     color="red"
                    //                     style={{ cursor: 'pointer', }}
                    //                     onClick={() => handleDelete(props.value)}
                    //                 />
                    //             </g>
                    //          }
                    //         </>
                    //       )}
                    //     </g>
                    //   ),
                    renderTick: (props) => {
                        const currentPoint = activeTab.value.find((point: IPoint) => point.x === props.value);
                
                        const shouldShowIcons =
                    (currentPoint?.isFromCorrectAnswer && !currentPoint?.isEdited) ||
                    (layout?.edit_point || layout?.delete_point); 
                
                        return (
                            <g
                                onClick={() => {
                                    if (shouldShowIcons) {
                                        setClickedBar(props.value);
                                    }
                                }}
                            >
                                <text
                                    x={props.x}
                                    y={props.y + 20}
                                    textAnchor="middle"
                                    dominantBaseline="middle"
                                >
                                    {props.value}
                                </text>
                                {clickedBar === props.value && shouldShowIcons && (
                                    <g transform={`translate(${props.x - 40}, ${props.y + 30})`}>
                                        <rect
                                        width={(layout?.delete_point && layout?.edit_point) ? "60" : "50"}
                                        height="30"
                                            fill="white"
                                            stroke="gray"
                                            strokeWidth="0.5"
                                            rx="5"
                                            ry="5"
                                        />
                                        {(layout?.edit_point || currentPoint?.isFromCorrectAnswer ) && (
                                            <FaEdit
                                                color='gray'
                                                className='cursor-pointer hover:opacity-70'
                                                x={10}
                                                y={5}
                                                onClick={(e) => {
                                                    e.stopPropagation(); 
                                                    handleEdit(props.value);
                                                }}
                                            />
                                        )}
                                        {(layout?.delete_point || currentPoint?.isFromCorrectAnswer) && (
                                            <FaTrash
                                                color="red"
                                                className='cursor-pointer hover:opacity-70'
                                                x={30}
                                                y={5}
                                                onClick={(e) => {
                                                    e.stopPropagation(); 
                                                    handleDelete(props.value);
                                                }}
                                            />
                                        )}
                                        {/* Close icon */}
                                        <g
                                            transform="translate(-10, -10)"
                                            onClick={(e) => {
                                                e.stopPropagation(); 
                                                setClickedBar(null); 
                                            }}
                                        >
                                            <AiFillCloseCircle
                                                color="red"
                                                className='cursor-pointer hover:opacity-70'
                                            />
                                        </g>
                                    </g>
                                )}
                            </g>
                        );
                    }
                }}
                axisLeft={null}
                enableGridX={layout && layout?.show_gridlines ? ["both", "x_only"].includes(layout.show_gridlines) : false}
                enableGridY={layout && layout?.show_gridlines ? ["both", "y_only"].includes(layout.show_gridlines) : false}
                padding={0.4}
                layers={[
                    'grid',
                    'axes',
                    ({ bars }) => (
                        <g>
                            {bars.map((bar, index) => {
                                const bulletCount = Math.round(bar.data.value); // Number of bullets for this value
                                const startY = bar.y + bar.height - (bulletRadius * 2 + spacing) * bulletCount;
                                const pointId = bar?.data?.data?.id;

                                return (
                                    <g key={bar.key}>
                                        {[...Array(bulletCount)].map((_, i) => {
                                            return (
                                                <g
                                                    key={i}
                                                    className="bar-dots-wrapper"
                                                    onMouseEnter={() => {
                                                        if(layout?.order_point){
                                                            setHoveredPointId(pointId);
                                                        }
                                                    }}
                                                    onMouseLeave={() => {
                                                        if(hoveredPointId) setHoveredPointId(null);
                                                    }}
                                                >
                                                <path
                                                    d="M24.778,21.419L19.276,15.917L24.777,10.415L21.949,7.585L16.447,13.087L10.945,7.585L8.117,10.415L13.618,15.917L8.116,21.419L10.946,24.248L16.447,18.746L21.948,24.248Z"
                                                    // fill="#3498db"
                                                    fill="#3182CE"
                                                    transform={`translate(${bar.x + bar.width / 2 - 16}, ${
                                                        startY + (bulletRadius * 2 + spacing) * i - 12
                                                    }) scale(${scaleXY},${scaleXY})`}
                                                    onMouseDown={(event) =>
                                                        handleDragStart(event, index)
                                                    }
                                                    style={{ cursor: 'grab' }}
                                                />
                                            </g>
                                        )}
                                    )}
                                        {bulletCount === 0 && (
                                            <g 
                                                className="bar-dots-wrapper"
                                                onMouseEnter={() => {
                                                    if(layout?.order_point){
                                                        setHoveredPointId(pointId);
                                                    }
                                                }}
                                                onMouseLeave={() => {
                                                    if(hoveredPointId) setHoveredPointId(null);
                                                }}
                                            >
                                            <path
                                                d="M24.778,21.419L19.276,15.917L24.777,10.415L21.949,7.585L16.447,13.087L10.945,7.585L8.117,10.415L13.618,15.917L8.116,21.419L10.946,24.248L16.447,18.746L21.948,24.248Z"
                                                fill="rgba(0,0,0,0.1)"
                                                transform={`translate(${bar.x + bar.width / 2 - 16}, ${
                                                    bar.y + bar.height - bulletRadius - 12
                                                }) scale(${scaleXY}, ${scaleXY})`}
                                                onMouseDown={(event) =>
                                                    handleDragStart(event, index)
                                                }
                                                style={{ cursor: 'grab' }}
                                            />
                                        </g>
                                        )}

                                        {
                                            draggedIndex == index && 
                                            <line
                                                x1={linePosition.x1}
                                                y1={linePosition.y1}
                                                x2={linePosition.x2}
                                                y2={linePosition.y2}
                                                stroke="black"
                                                strokeWidth="1"
                                                strokeDasharray={"5,5"}
                                            />
                                        }

                                        {
                                            !(layout && layout?.show_gridlines && ["both", "y_only"].includes(layout?.show_gridlines)) &&
                                            <line
                                                x1={0}
                                                y1={460}
                                                x2={300 * bars.length}
                                                y2={460}
                                                stroke="black"
                                                strokeWidth="1"
                                            />
                                        }

                                        {hoveredPointId === pointId && !!layout?.order_point && (
                                        <>
                                        {
                                            index != 0 && (
                                                <g 
                                                    transform={`translate(${bar.x + bar.width / 2 - 40}, ${440})`}
                                                >
                                                    <FaArrowCircleLeft 
                                                        color = "black"
                                                        size={24}
                                                        style={{cursor: 'pointer'}}
                                                        onClick={() => {handleReorderPoints(index, "left")}}
                                                    />
                                                </g>
                                            )
                                        }
                                        {
                                            index != bars.length - 1 && (
                                                <g 
                                                    transform={`translate(${bar.x + bar.width / 2 + 20}, ${440})`}
                                                >
                                                    <FaArrowCircleRight 
                                                        color = "black"
                                                        size={24}
                                                        style={{cursor: 'pointer'}}
                                                        onClick={() => {handleReorderPoints(index, "right")}}
                                                    />
                                                </g>
                                            )
                                        }
                                    </>
                                )}
                                    </g>
                                );
                            })}
                        </g>
                    ),
                ]}
            />

            <ModalEditBarValue
                showModal={showModal}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleCancel={handleModalClose}
                handleSave={handleOkForEditBar}
            />
        </div>
    ) : (<div>Loading ...</div>);
}
