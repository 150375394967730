import "mathjax/es5/tex-mml-chtml";
import store, { RootState } from "redux/store";
import { symbolConfigLayouts } from "../SymbolsConfig";
import { useSelector } from "react-redux";

declare global {
  interface Window {
    MathJax: {
      typesetPromise: (elements: any[]) => Promise<void>;
    };
  }
}
interface VirtualKeyboardLayout {
  label: string;
  tooltip: string;
  rows: string[][];
}

export const createMathliveForMath = (editor: any, pageType: any) => {
  return {
    name: "math",
    iconURL: "https://img.icons8.com/ios/50/000000/sigma.png",
    exec: () => openMathModal(editor, pageType),
  };
};

const openMathModal = (editor: any, pageType: any) => {
  console.log("pageType...", pageType);
  const state = store.getState();
  const subQuestions = state?.editQuestion?.sub_questions;
  const horizontalLayout =
    state.question.subQuestions?.["mewrt"]?.more_options?.ui_style
      ?.horizontal_layout;

  const horizontalLayoutObeService =
    state.preview?.assessment?.subQuestions?.find(
      (subQuestion) => subQuestion.type === "mewrt"
    )?.more_options?.ui_style?.horizontal_layout;

  const horizontalLayoutEditMode = subQuestions?.find(
    (subQuestion) => subQuestion.type === "mewrt"
  )?.more_options?.ui_style?.horizontal_layout;

  const currentPath = window.location.pathname;
  const isEditPage = currentPath.includes("edit-subquestion");
  const isObeServicePage = pageType === "prevLMSTeacher";
  const isObeServicePageStudent = pageType === "prevQuesStudent";

  // const isEditMode = state?.editQuestion?.sub_questions?.[0]?.settings?.symbols

  const symbolsEditMode = subQuestions?.find(
    (subQuestion) => subQuestion.type === "mewrt"
  )?.settings?.symbols;
  const numberPadEditMode = subQuestions?.find(
    (subQuestion) => subQuestion.type === "mewrt"
  )?.settings?.numberPad;

  const symbols = state.question.subQuestions?.["mewrt"]?.settings?.symbols;
  const numberPad = state.question.subQuestions?.["mewrt"]?.settings?.numberPad;

  //* Get objects (symbols,numberPad) for OBE Service
  const symbolsObeService = state.preview?.assessment?.subQuestions?.find(
    (subQuestion) => subQuestion.type === "mewrt"
  )?.settings?.symbols;

  const numberPadObeService = state.preview?.assessment?.subQuestions?.find(
    (subQuestion) => subQuestion.type === "mewrt"
  )?.settings?.numberPad;

  console.log("symbolsEditMode....",symbols)
  type VirtualKeyboardName = "numeric" | "alphabetic" | "symbols" | "greek";
  // const defaultLayouts: VirtualKeyboardName[] = [
  //   "numeric",
  //   "alphabetic",
  //   "symbols",
  //   "greek",
  // ];
  const alphabatic: VirtualKeyboardName = "alphabetic";
  const getLayouts = (symbols: string[]): VirtualKeyboardLayout[] => {
    return symbols
      .filter((symbol) => symbolConfigLayouts[symbol.toLowerCase()])
      .map((symbol) => symbolConfigLayouts[symbol.toLowerCase()]);
  };

  const symbolLayouts: VirtualKeyboardLayout[] = getLayouts(
    isEditPage ? symbolsEditMode
      : isObeServicePage ? symbolsObeService : isObeServicePageStudent ? symbolsObeService
      : symbolsObeService ?
       symbols : symbols
  );
  const filteredSymbolLayouts = symbolLayouts.filter(
    (layout) => layout.label !== "QWERTY"
  );

  const chunkSize = 4;
  const groupedArray = [];
  const isEditmodeNumber = isEditPage
    ? numberPadEditMode
    : isObeServicePage ? numberPadObeService : isObeServicePageStudent ? numberPadObeService
    : numberPadObeService ?
     numberPad : numberPad;
  for (let i = 0; i < isEditmodeNumber?.length; i += chunkSize) {
    groupedArray?.push(isEditmodeNumber?.slice(i, i + chunkSize));
  }

  const halfIndex = Math.ceil(isEditmodeNumber?.length / 2);
  const groupedNumberPad = [
    isEditmodeNumber?.slice(0, halfIndex),
    isEditmodeNumber?.slice(halfIndex),
  ];

  const layouts =
    horizontalLayout || horizontalLayoutObeService || horizontalLayoutEditMode
      ? [
          {
            label: "Numbers",
            tooltip: "Numbers and basic operators",
            rows: groupedNumberPad,
          },
        ]
      : [
          {
            label: "Numbers",
            tooltip: "Numbers and basic operators",
            rows: groupedArray,
          },
          ...(symbolLayouts.some((layout) => layout.label === "QWERTY")
            ? [alphabatic]
            : []),
          ...filteredSymbolLayouts,
        ];

  window.mathVirtualKeyboard.layouts = layouts;

  const modal = document.createElement("div");
  modal.style.position = "fixed";
  modal.style.top = "50%";
  modal.style.left = "50%";
  modal.style.transform = "translate(-50%, -50%)";
  modal.style.backgroundColor = "#fff";
  // modal.style.padding = '20px';
  modal.style.width = "400px";
  modal.style.boxShadow = "0 0 10px rgba(0,0,0,0.5)";
  modal.style.zIndex = "1000"; // Ensure modal is on top

  const topcontainer = document.createElement("div");
  topcontainer.style.backgroundColor = "rgb(238, 238, 238)";
  topcontainer.style.height = "50px";
  topcontainer.style.padding = "16px 10px 10px 10px";

  const title = document.createElement("h2");
  title.innerHTML = "Edit Math";
  title.style.fontWeight = "600";
  topcontainer.appendChild(title);
  modal.appendChild(topcontainer);

  const innerDiv = document.createElement("div");
  innerDiv.style.padding = "20px";
  modal.appendChild(innerDiv);

  const mathContainer = document.createElement("div");
  mathContainer.id = "math-container";
  innerDiv.appendChild(mathContainer);

  addNewMathField(mathContainer, innerDiv, editor, modal);

  const closeButton = document.createElement("button");
  closeButton.textContent = "Close";
  closeButton.style.marginTop = "10px";
  closeButton.style.marginLeft = "10px";
  closeButton.style.background = "rgb(250, 155, 49)";
  closeButton.style.padding = "10px";
  closeButton.style.height = "37px";
  closeButton.style.color = "white";
  closeButton.style.alignItems = "center";
  closeButton.style.borderRadius = "8px";
  closeButton.onclick = () => {
    closeModal(modal);
  };
  innerDiv.appendChild(closeButton);

  document.body.appendChild(modal);
};

const addNewMathField = (
  container: HTMLElement,
  innerDiv: HTMLElement,
  editor: any,
  modal: HTMLElement
) => {
  const mathInput = document.createElement("math-field");
  // @ts-ignore
  mathInput.mathModeSpace = "\\:";
  mathInput.style.border = "1px solid black";
  mathInput.style.padding = "10px";
  mathInput.style.margin = "10px 0";
  mathInput.style.minHeight = "50px";
  mathInput.style.width = "100%";
  container.appendChild(mathInput);

  const insertButton = document.createElement("button");
  insertButton.textContent = "Add Equation";
  insertButton.style.marginTop = "10px";
  insertButton.style.marginLeft = "10px";
  insertButton.style.background = "rgb(250, 155, 49)";
  insertButton.style.padding = "10px";
  insertButton.style.height = "37px";
  insertButton.style.color = "white";
  insertButton.style.alignItems = "center";
  insertButton.style.borderRadius = "8px";

  insertButton.onclick = () => {
    const latexArray = Array.from(container.children).map((child) =>
      (child as any).getValue()
    );
    Promise.all(latexArray.map(renderMath)).then((mathHtmlArray) => {
      console.log(mathHtmlArray.length, " length ");
      insertMathIntoEditor(editor, mathHtmlArray);
    });
    closeModal(modal);
  };

  innerDiv.appendChild(insertButton);
};

const closeModal = (modal: HTMLElement) => {
  document.body.removeChild(modal);
  // window.mathVirtualKeyboard.layouts = [];
};

const renderMath = (latex: string): Promise<string> => {
  return new Promise((resolve) => {
    const span = document.createElement("span");
    span.className = "math-expression";
    span.style.border = "1px dotted black";
    span.style.padding = "5px";
    span.style.userSelect = "none";
    span.style.pointerEvents = "none";
    span.contentEditable = "false";

    const container = document.createElement("div");
    container.className = "math-expression";
    container.innerHTML = `\\(${latex}\\)`;
    window.MathJax.typesetPromise([container]).then(() => {
      span.appendChild(container.firstChild);
      resolve(span.outerHTML);
    });
  });
};

const insertMathIntoEditor = (editor, mathHtmlArray) => {
  try {
    if (editor && editor.s && editor.s.insertHTML) {
      editor.s.insertHTML(mathHtmlArray.join(" "));
    } else {
      console.error("Editor instance is not ready or is destroyed.");
    }
  } catch (error) {
    console.error("Failed to insert HTML into Jodit editor:", error);
  }
};
